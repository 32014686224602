import React, { FC } from 'react';
import { ExportOutlined } from '@ant-design/icons';
import { EEventDetailsItemLabel } from 'common/const/enum';
import { IPatient } from 'app/models/patient.models';
import { toPatientDetails } from 'entities/Events/Events.helper';

interface IComponentProps {
  patient: IPatient;
  ccdaLink?: string;
}

export const EventDetails: FC<IComponentProps> = ({ patient, ccdaLink }) => {
  const details = toPatientDetails(patient);

  return (
    <div className="event-details">
      <div className="event-details__container">
        {Object.entries(details).map(([key, value], index) => {
          return (
            <div key={index} className="event-details__item">
              <span className="event-details__item-label">{`${
                EEventDetailsItemLabel[key as keyof typeof EEventDetailsItemLabel]
              }:`}</span>

              <span>{value}</span>
            </div>
          );
        })}
      </div>

      {ccdaLink && (
        <a onClick={() => window.open(ccdaLink, '_blank')}>
          <span>Celeri Patient Details</span>

          <ExportOutlined />
        </a>
      )}

      {patient.id && (
        <a onClick={() => window.open(`https://dashboard.celerihealth.com/home/resources/patients/${patient.id}`, '_blank')}>
          <span>Celeri Patient Detail</span>

          <ExportOutlined />
        </a>
      )}
    </div>
  );
};
