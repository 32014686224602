import React from 'react';
import { notification } from 'antd';
import { NEW_EVENT_NOTIFICATION_DURATION_S } from 'common/config';

export const useNotification = () => {
  const [api, contextHolder] = notification.useNotification();

  const showNewEventNotification = () => {
    api.open({
      message: 'New Event',
      description: (
        <>
          <span>Please check your dashboard inbox!</span>

          <audio src="/inbox_notification.mp3" autoPlay={true} muted={false} />
        </>
      ),
      duration: NEW_EVENT_NOTIFICATION_DURATION_S,
      placement: 'topRight',
    });
  };

  return { newEventNotificationContext: contextHolder, showNewEventNotification };
};
