import dayjs from 'dayjs';
import { getFormattedTime, padTo2Digits } from 'common/helpers/time.helper';
import { EDateFormat, ETimeFormat } from 'common/const/enum';
import { ITimer } from 'app/models/timer.models';

export const getTimerFormattedValue = (days: number, hours: number, minutes: number, seconds: number) => {
  return `${padTo2Digits(hours + days * 24)}:${padTo2Digits(minutes)}:${padTo2Digits(seconds)}`;
};

export const getStopwatchOffset = (milliseconds: number) => {
  const stopwatchOffset = new Date();
  stopwatchOffset.setMilliseconds(stopwatchOffset.getMilliseconds() + milliseconds);
  return stopwatchOffset;
};

export const getTimerInfo = (timer: ITimer | null, startDate?: string, endDate?: string) => {
  const initialTimerValue = getStopwatchOffset(timer?.time?.time || 0);
  const commonMilliseconds = timer?.commonResults.reduce((acc, curr) => acc + curr.time, 0) || 0;
  const commonSpentTime = `Spent time for the last ${dayjs().diff(startDate, 'days')} days: ${getFormattedTime(
    commonMilliseconds,
    ETimeFormat.FullTime,
  )}`;
  const billingRange =
    startDate && endDate
      ? `${dayjs(startDate).format(EDateFormat.FullDateShortSlash)} - ${dayjs(endDate).format(EDateFormat.FullDateShortSlash)}`
      : null;

  return { initialTimerValue, commonSpentTime, billingRange };
};

export const timerValueToMilliseconds = (hours: number, minutes: number, seconds: number) => {
  return (hours * 3600 + minutes * 60 + seconds) * 1000;
};
