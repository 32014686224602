import React, { FC } from 'react';
import dayjs from 'dayjs';
import parse from 'html-react-parser';
import { ETimeFormat } from 'common/const/enum';
import { IMessage, IMessageItemMessage } from 'app/models/message.models';
import { messageToHtmlString } from 'entities/Messages/Messages.helper';

interface IComponentProps {
  item: IMessage;
  isCurrentUserMessage: boolean;
}

export const MessageType: FC<IComponentProps> = ({ item, isCurrentUserMessage }) => {
  const { createdAt, userName, data, patientName } = item;
  const time = dayjs(createdAt).format(ETimeFormat.ShortTime12);
  const message = (data as IMessageItemMessage).message;
  const remoteParticipantName = userName || patientName;

  return isCurrentUserMessage ? (
    <div className="messages__message current-user-message">
      <div className="messages__message-time">{time}</div>

      <div className="messages__message-container">
        <div className="messages__message-data">{parse(messageToHtmlString(message))}</div>
      </div>
    </div>
  ) : (
    <div className="messages__message">
      <div className="messages__message-avatar">{remoteParticipantName?.match(/\b(\w)/g)?.join('')}</div>

      <div className="messages__message-container">
        <div className="messages__message-username">{remoteParticipantName}</div>

        <div className="messages__message-data">{parse(messageToHtmlString(message))}</div>
      </div>

      <div className="messages__message-time">{time}</div>
    </div>
  );
};
