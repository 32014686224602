import React, { FC } from 'react';
import { Layout } from 'antd';
import { InfoPanel } from 'common/components/InfoPanel';
import { EventList } from 'entities/Events/components/EventList';

interface IComponentProps {}

const DashboardComponent: FC<IComponentProps> = () => {
  return (
    <Layout className="container">
      <InfoPanel />

      <EventList />
    </Layout>
  );
};

export const Dashboard = DashboardComponent;
