import { useState, useEffect } from 'react';

export const useCallTimer = (onCall: boolean) => {
  const [milliseconds, setMilliseconds] = useState(0);

  useEffect(() => {
    let interval: NodeJS.Timeout;

    if (onCall) {
      interval = setInterval(() => {
        setMilliseconds((prev) => prev + 1000);
      }, 1000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [onCall]);

  return { milliseconds };
};
