import React, { useCallback } from 'react';
import { Button, Modal, Spin } from 'antd';
import { useAppSelector } from 'common/hooks/useAppSelector';
import { useAppDispatch } from 'common/hooks/useAppDispatch';
import { RouterPrompt } from 'common/components/RouterPrompt';
import { useBlocker } from 'common/hooks/useBlocker';
import { EEventActionType, EEventStatus, EModalKey, ETimeFormat } from 'common/const/enum';
import { getFormattedTime } from 'common/helpers/time.helper';
import { ITransition } from 'app/models/global.models';

interface IComponentProps {
  eventId: number;
  blockRouter: boolean;
}

export const EventRouterHandler: React.FC<IComponentProps> = ({ eventId, blockRouter }) => {
  const { timer, event } = useAppSelector((state) => ({ timer: state.timer, event: state.event }));
  const { event: eventDispatch, timer: timerDispatch, modalCollection: modalCollectionDispatch } = useAppDispatch();

  const { loading: timerLoading, timerIsRunning, untrackedTime } = timer;
  const { data: eventData, loading: evetnLoading, action, text, physicianId } = event;
  const { moveEventToInbox, completeEvent, escalateEvent } = eventDispatch;
  const { stopTimer, deleteTimer } = timerDispatch;
  const { hideModal } = modalCollectionDispatch;

  const eventStatus = eventData?.status;

  const blocker = useCallback(
    (autoUnblockingTx: ITransition) => {
      switch (true) {
        case action === EEventActionType.Complete: {
          completeEvent({
            id: eventId,
            text: text as string,
            onSuccess: () => {
              hideModal(EModalKey.Complete);
              autoUnblockingTx.retry();
            },
          });
          break;
        }
        case action === EEventActionType.Escalate: {
          escalateEvent({
            id: eventId,
            text: text as string,
            escalated: true,
            physicianId: physicianId as number,
            onSuccess: () => {
              hideModal(EModalKey.Escalate);
              autoUnblockingTx.retry();
            },
          });
          break;
        }
        case action === EEventActionType.Unescalate: {
          escalateEvent({
            id: eventId,
            escalated: false,
            onSuccess: () => {
              autoUnblockingTx.retry();
            },
          });
          break;
        }
        case blockRouter && autoUnblockingTx.action === 'PUSH':
        case blockRouter && autoUnblockingTx.action === 'POP' && !action:
        case blockRouter && action === EEventActionType.Back: {
          moveEventToInbox({
            id: eventId,
            onSuccess: () => autoUnblockingTx.retry(),
          });
          break;
        }
        default: {
          autoUnblockingTx.retry();
          break;
        }
      }
    },
    [action, text, physicianId, blockRouter],
  );

  useBlocker(blockRouter && !timerIsRunning, blocker);

  const renderPrompt = (isActive: boolean, onConfirm?: (value: unknown) => void, onCancel?: (value: unknown) => void) => {
    return (
      isActive && (
        <Modal title="New Notification" open={true} footer={false} onCancel={onCancel} width={600}>
          <Spin spinning={timerLoading || evetnLoading}>
            <div className="modal__body">
              <p>
                From the review start you’ve spent {getFormattedTime(untrackedTime, ETimeFormat.FullTime)}, but haven’t stopped
                the timer. Save time or delete?
              </p>
            </div>

            <div className="modal__footer">
              <Button
                className="btn blue"
                onClick={() => {
                  eventStatus === EEventStatus.UnderReview
                    ? stopTimer({ eventId, onSuccess: () => onConfirm?.(undefined) })
                    : onConfirm?.(undefined);
                }}
              >
                Save and Leave
              </Button>

              <Button
                className="btn orange"
                onClick={() => {
                  eventStatus === EEventStatus.UnderReview
                    ? deleteTimer({ eventId, onSuccess: () => onConfirm?.(undefined) })
                    : onConfirm?.(undefined);
                }}
              >
                Delete and Leave
              </Button>

              <Button className="btn default" onClick={onCancel}>
                Cancel
              </Button>
            </div>
          </Spin>
        </Modal>
      )
    );
  };

  return <RouterPrompt when={timerIsRunning} renderPrompt={renderPrompt} />;
};
