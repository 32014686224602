import { createModel } from '@rematch/core';
import { axiosErrorHandler } from 'common/helpers/axios.helper';
import { IRootModel } from 'app/store/slices';
import {
  IMessage,
  IMessageCollectionParams,
  IMessageCollectionState,
  IMessageSendPayload,
  IMessageState,
} from 'app/models/message.models';
import { messageService } from 'app/store/services/message.service';

export const messageCollection = createModel<IRootModel>()({
  state: {
    data: null,
    loading: false,
  } as IMessageCollectionState,
  reducers: {
    setMessageCollection: (state, payload: IMessage[]) => ({ ...state, data: payload }),
    setMessageCollectionLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
  },
  effects: (dispatch) => ({
    async getMessages(params: IMessageCollectionParams) {
      dispatch.messageCollection.setMessageCollectionLoading(true);

      await messageService
        .getMessages(params)
        .then((response) => {
          dispatch.messageCollection.setMessageCollection(response.data);
        })
        .catch(axiosErrorHandler)
        .finally(() => {
          dispatch.messageCollection.setMessageCollectionLoading(false);
        });
    },
  }),
});

export const message = createModel<IRootModel>()({
  state: {
    data: null,
    loading: false,
  } as IMessageState,
  reducers: {
    setMessage: (state, payload: IMessage) => ({ ...state, data: payload }),
    setMessageLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
  },
  effects: (dispatch) => ({
    async sendMessage(payload: IMessageSendPayload) {
      dispatch.message.setMessageLoading(true);

      await messageService
        .sendMessage(payload)
        .catch(axiosErrorHandler)
        .finally(() => {
          dispatch.message.setMessageLoading(false);
        });
    },
  }),
});
