import axios from 'axios';
import { IAfterCallPayload, ICallToken, IParticipantDetails, ICallVideoTokenPayload } from 'app/models/twilio.models';

const basePath = '/conversations';

export const twilioService = {
  getVoiceCallToken: (): Promise<ICallToken> => axios.post(`${basePath}/call`),
  getVideoCallToken: (payload: ICallVideoTokenPayload): Promise<ICallToken> => axios.post(`${basePath}/video-call`, payload),
  trackVoiceCallDuration: (payload: IAfterCallPayload): Promise<void> => axios.post(`${basePath}/after-call`, payload),
  getParticipantByIdentity: (identity: string): Promise<IParticipantDetails> =>
    axios.get(`${basePath}/video-call/participants/${identity}`),
};
