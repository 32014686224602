import { createModel } from '@rematch/core';
import { axiosErrorHandler } from 'common/helpers/axios.helper';
import { IRootModel } from 'app/store/slices';
import { ITimer, ITimerPayload, ITimerState, ITimerUpdatePayload } from 'app/models/timer.models';
import { timerService } from 'app/store/services/timer.service';

let timerId: NodeJS.Timer;

export const timer = createModel<IRootModel>()({
  state: {
    data: null,
    loading: false,
    timerIsRunning: false,
    untrackedTime: 0,
  } as ITimerState,
  reducers: {
    setTimer: (state, payload: ITimer) => ({ ...state, data: payload }),
    setTimerLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
    setTimerIsRunning: (state, payload: boolean) => ({ ...state, timerIsRunning: payload }),
    setUntrackedTime: (state, payload: number) => ({ ...state, untrackedTime: payload }),
    clearTimer: (state) => ({ ...state, data: null, timerIsRunning: false, untrackedTime: 0 }),
  },
  effects: (dispatch) => ({
    async getTimer(eventId: number) {
      dispatch.timer.setTimerLoading(true);

      await timerService
        .getTimer(eventId)
        .then((response) => {
          dispatch.timer.setTimer(response);
        })
        .catch(axiosErrorHandler)
        .finally(() => {
          dispatch.timer.setTimerLoading(false);
        });
    },
    async pingTimer(eventId: number) {
      timerId = setInterval(() => {
        timerService.pingTimer(eventId).catch(axiosErrorHandler);
      }, 10000);
    },
    clearPingTimer() {
      clearInterval(timerId);
    },
    async startTimer(payload: ITimerPayload) {
      dispatch.timer.setTimerLoading(true);

      await timerService
        .startTimer(payload.eventId)
        .then(() => {
          dispatch.timer.setTimerIsRunning(true);
          dispatch.timer.pingTimer(payload.eventId);
          dispatch.global.onSuccess(payload);
        })
        .catch(axiosErrorHandler)
        .finally(() => {
          dispatch.timer.setTimerLoading(false);
        });
    },
    async stopTimer(payload: ITimerPayload) {
      dispatch.timer.setTimerLoading(true);

      return await timerService
        .stopTimer(payload.eventId)
        .then((response) => {
          dispatch.timer.setTimerIsRunning(false);
          dispatch.timer.clearPingTimer();
          dispatch.global.onSuccess(payload);
          dispatch.history.getHistory(payload.eventId);

          return response;
        })
        .catch(axiosErrorHandler)
        .finally(() => {
          dispatch.timer.setTimerLoading(false);
        });
    },
    async deleteTimer(payload: ITimerPayload) {
      dispatch.timer.setTimerLoading(true);

      await timerService
        .deleteTimer(payload.eventId)
        .then(() => {
          dispatch.timer.setTimerIsRunning(false);
          dispatch.timer.clearPingTimer();
          dispatch.global.onSuccess(payload);
          dispatch.history.getHistory(payload.eventId);
        })
        .catch(axiosErrorHandler)
        .finally(() => {
          dispatch.timer.setTimerLoading(false);
        });
    },
    async updateTimer(payload: ITimerUpdatePayload) {
      dispatch.timer.setTimerLoading(true);

      await timerService
        .updateTimer(payload)
        .then(() => {
          dispatch.timer.getTimer(payload.eventId);
          dispatch.global.onSuccess(payload);
          dispatch.history.getHistory(payload.eventId);
        })
        .catch(axiosErrorHandler)
        .finally(() => {
          dispatch.timer.setTimerLoading(false);
        });
    },
  }),
});
