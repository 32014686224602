import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { ETimeFormat } from 'common/const/enum';
dayjs.extend(duration);

export const getFormattedTime = (milliseconds: number = 0, format: ETimeFormat) => {
  const duration = dayjs.duration(milliseconds);

  const days = duration.days();
  const hours = duration.hours();
  const minutes = duration.minutes();
  const seconds = duration.seconds();

  switch (format) {
    case ETimeFormat.TimeWithUnit: {
      const formattedTimeArray: string[] = [];

      if (days > 0) {
        formattedTimeArray.push(`${days}d`);
      }

      if (hours > 0) {
        formattedTimeArray.push(`${hours}h`);
      }

      if (minutes > 0) {
        formattedTimeArray.push(`${minutes}m`);
      }

      if (days === 0 && hours === 0 && seconds > 0) {
        formattedTimeArray.push(`${seconds}s`);
      }

      return formattedTimeArray.join(' ');
    }
    case ETimeFormat.FullTime: {
      return `${padTo2Digits(hours + days * 24)}:${padTo2Digits(minutes)}:${padTo2Digits(seconds)}`;
    }
    case ETimeFormat.MinSec: {
      return `${padTo2Digits(minutes)}:${padTo2Digits(seconds)}`;
    }
    case ETimeFormat.ConversationDuration: {
      const formattedTimeArray: string[] = [];
      if (hours > 0) {
        formattedTimeArray.push(`${hours + days * 24}h`);
      }

      if (minutes > 0) {
        formattedTimeArray.push(`${minutes}m`);
      }

      if (seconds > 0) {
        formattedTimeArray.push(`${seconds}s`);
      }

      return formattedTimeArray.join(' ');
    }
    default: {
      return milliseconds;
    }
  }
};

export function padTo2Digits(num: number) {
  return num.toString().padStart(2, '0');
}
