export const convertibleKeyCodes: { [key: string]: string } = {
  ['I’m feeling low/depressed']: 'Low/Depressed',
  ['I’m having a hard time controlling my worries']: 'Worry/Anxiety',
  ['I’m feeling overly stressed about a situation in my life']: 'Stress',
  ['I think that my substance use is problematic']: 'Substance Use',
  ['I want to make a change in my life and could use some help getting started']: 'Make Change',
  ['I don’t feel as healthy as I want to']: 'Not Healthy',
  ['I think I could use someone to talk to']: 'Want to Talk',
  ['I’d rather not say at this time']: 'Rather Not Say',
  ['I need to vent and could use someone to listen to me']: 'Vent',
  ['I need someone to direct me and provide guidance']: 'Guide',
  ['I need someone to listen to me and guide me']: 'Listen & Guide',
  ['I’m not quite sure']: 'Unsure',
  ['I’m not sure but I want to talk to someone about it']: 'Not Sure',
};
