import React, { FC } from 'react';
import dayjs from 'dayjs';
import { ETimeFormat } from 'common/const/enum';
import { getFormattedTime } from 'common/helpers/time.helper';
import { IMessage, IMessageItemVideo } from 'app/models/message.models';
import { ReactComponent as VideoIcon } from 'app/assets/images/svg/video-camera-outlined.svg';

interface IComponentProps {
  item: IMessage;
}

export const VideoCallType: FC<IComponentProps> = ({ item }) => {
  const { createdAt, patientName, data } = item;
  const time = dayjs(createdAt).format(ETimeFormat.ShortTime12);
  const duration = (data as IMessageItemVideo).duration;

  return (
    <div className="messages__video-call">
      <div className="messages__video-call-avatar">{patientName?.match(/\b(\w)/g)?.join('')}</div>

      <div className="messages__video-call-container">
        <VideoIcon />

        <div>
          <div className="messages__video-call-username">{patientName}</div>

          <div className="messages__video-call-data">{`Outgoing call ${
            duration ? 'for ' + getFormattedTime(duration * 1000, ETimeFormat.TimeWithUnit) : ''
          }`}</div>
        </div>
      </div>

      <div className="messages__video-call-time">{time}</div>
    </div>
  );
};
