import { createModel } from '@rematch/core';
import { axiosErrorHandler, clearCreds, getCreds } from 'common/helpers/axios.helper';
import { SocketIOService } from 'common/SocketIoService';
import { IAuthPayload, IAuthState, IToken } from 'app/models/auth.models';
import { IRootModel } from 'app/store/slices';
import { authService } from 'app/store/services/auth.service';
import { saveAuthCreds } from 'entities/Auth/Auth.helper';

export const auth = createModel<IRootModel>()({
  state: {
    data: null,
    loading: true,
  } as IAuthState,
  reducers: {
    setAuth: (state, payload: IToken) => ({ ...state, data: payload }),
    setAuthLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
    clearAuth: (state) => ({ ...state, data: null }),
  },
  effects: (dispatch) => ({
    async initAuth() {
      const creds = await getCreds();

      if (creds) {
        const now = Math.round(Date.now() / 1000);

        if (creds.refresh && creds.refresh.expiredAt < now) {
          clearCreds();
          dispatch.auth.setAuthLoading(false);
          return;
        }

        if (creds.access && creds.access.expiredAt < now) {
          if (creds.refresh && creds.refresh.token) {
            await dispatch.auth.refreshToken(creds.refresh.token);
            return;
          } else {
            clearCreds();
            return;
          }
        }

        SocketIOService.connect(creds.access?.token as string);
        dispatch.auth.setAuth(creds);
      }

      dispatch.auth.setAuthLoading(false);
    },
    async login(payload: IAuthPayload) {
      dispatch.auth.setAuthLoading(true);

      await authService
        .login(payload)
        .then((response) => {
          saveAuthCreds(dispatch, response);
          SocketIOService.connect(response.access_token);
        })
        .catch(axiosErrorHandler)
        .finally(() => {
          dispatch.auth.setAuthLoading(false);
        });
    },
    async refreshToken(refreshToken: string) {
      dispatch.auth.setAuthLoading(true);
      SocketIOService.disconnect();

      return await authService
        .refreshToken(refreshToken)
        .then((response) => {
          saveAuthCreds(dispatch, response);
          SocketIOService.connect(response.access_token);
          return response.access_token;
        })
        .catch((error) => {
          axiosErrorHandler(error);
          dispatch.auth.logout();
        })
        .finally(() => {
          dispatch.auth.setAuthLoading(false);
        });
    },
    async logout() {
      clearCreds();
      dispatch.auth.clearAuth();
      dispatch.auth.setAuthLoading(false);
      SocketIOService.disconnect();
    },
  }),
});
