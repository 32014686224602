import { useContext, useEffect } from 'react';
import { UNSAFE_NavigationContext as NavigationContext, Navigator } from 'react-router-dom';
import { History } from 'history';
import { ITransition } from 'app/models/global.models';

export const useBlocker = (when: boolean, blocker: (autoUnblockingTx: ITransition) => void) => {
  const { navigator } = useContext(NavigationContext);

  useEffect(() => {
    if (!when) {
      return;
    }

    const unblock = (navigator as Navigator & Pick<History, 'block'>).block((tx) => {
      const autoUnblockingTx = {
        ...tx,
        retry() {
          unblock();
          tx.retry();
        },
      };

      blocker(autoUnblockingTx);
    });

    return unblock;
  }, [navigator, when, blocker]);
};
