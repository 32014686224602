import React, { FC, useEffect, useState } from 'react';
import { Button, Spin } from 'antd';
import { PlayCircleOutlined, PauseCircleOutlined, EditOutlined } from '@ant-design/icons';
import { useStopwatch } from 'react-timer-hook';
import { IdleTimer } from 'common/components/IdleTimer';
import { EVENT_IDLE_LIMIT_MS } from 'common/config';
import { useAppSelector } from 'common/hooks/useAppSelector';
import { useAppDispatch } from 'common/hooks/useAppDispatch';
import { EEventActionType, EModalKey } from 'common/const/enum';
import { history } from 'app/store';
import { TimeEditorModal } from 'entities/Modal/components/TimeEditorModal';
import { getStopwatchOffset, getTimerFormattedValue, getTimerInfo, timerValueToMilliseconds } from 'entities/Timer/Timer.helper';
import { InactivityModal } from 'entities/Modal/components/InactivityModal';

interface IComponentProps {
  eventId: number;
  canStart: boolean;
  autoStart: boolean;
}

export const Timer: FC<IComponentProps> = ({ eventId, canStart, autoStart }) => {
  const { days, hours, minutes, seconds, start, pause, reset } = useStopwatch();

  const { timer, event, twilio, inactivityModalParams } = useAppSelector((state) => ({
    timer: state.timer,
    event: state.event,
    twilio: state.twilio,
    inactivityModalParams: state.modalCollection.inactivityModalParams,
  }));
  const { timer: timerDispatch, event: eventDispatch, modalCollection: modalCollectionDispatch } = useAppDispatch();

  const [startTimeValue, setStartTimeValue] = useState<number>(0);

  const { data: timerData, loading: timerLoading, timerIsRunning } = timer;
  const { data: eventData, loading: eventLoading } = event;
  const { onCall } = twilio;
  const { open } = inactivityModalParams;
  const { startTimer, stopTimer, updateTimer, setUntrackedTime } = timerDispatch;
  const { setEventAction } = eventDispatch;
  const { showModal, hideModal, showInactivityModal } = modalCollectionDispatch;

  const { initialTimerValue } = getTimerInfo(timerData, eventData?.startDate, eventData?.endDate);

  const onStartClick = () => {
    startTimer({
      eventId,
      onSuccess: () => start(),
    });
  };

  const onStopClick = () => {
    stopTimer({
      eventId,
      onSuccess: () => pause(),
    });
  };

  const onEditClick = () => showModal(EModalKey.TimeEditor);

  const onTimeEditorCancelClick = () => hideModal(EModalKey.TimeEditor);

  const onTimeEditorSaveClick = (milliseconds: number) => {
    updateTimer({
      eventId,
      time: milliseconds,
      onSuccess: () => {
        hideModal(EModalKey.TimeEditor);
        reset(getStopwatchOffset(milliseconds), false);
      },
    });
  };

  const onInactivityModalResumeClick = () => {
    startTimer({
      eventId,
      onSuccess: () => {
        start();
        hideModal(EModalKey.Inactivity);
      },
    });
  };

  const onInactivityModalPauseClick = () => {
    hideModal(EModalKey.Inactivity);
  };

  const onInactivityModalMoveClick = () => {
    hideModal(EModalKey.Inactivity);
    setEventAction(EEventActionType.Back);
    history.back();
  };

  const onIdle = () => {
    stopTimer({
      eventId,
      onSuccess: () => {
        pause();
        showInactivityModal();
      },
    });
  };

  useEffect(() => {
    if (timerData) {
      if (canStart && autoStart) {
        startTimer({
          eventId,
          onSuccess: () => reset(initialTimerValue),
        });
      } else {
        reset(initialTimerValue, false);
      }

      if (timerData.time?.time) {
        setStartTimeValue(timerData.time.time);
      }
    }
  }, [timerData, canStart, autoStart]);

  useEffect(() => {
    if (timerData) {
      setUntrackedTime(timerValueToMilliseconds(hours + days * 24, minutes, seconds) - startTimeValue);
    }
  }, [timerData, seconds]);

  return (
    <div className="timer">
      <div className="timer__current-time">{`Your Timer: ${getTimerFormattedValue(days, hours, minutes, seconds)}`}</div>

      <div className="timer__controls">
        {timerIsRunning ? (
          <Button
            className="btn orange"
            icon={timerLoading ? <Spin size="small" /> : <PauseCircleOutlined />}
            onClick={onStopClick}
            disabled={timerLoading}
          >
            Stop
          </Button>
        ) : (
          <Button
            className="btn blue"
            icon={timerLoading ? <Spin size="small" /> : <PlayCircleOutlined />}
            onClick={onStartClick}
            disabled={timerLoading || !canStart}
          >
            Start
          </Button>
        )}

        <Button
          className="btn default"
          icon={timerLoading ? <Spin size="small" /> : <EditOutlined />}
          disabled={timerIsRunning || timerLoading}
          onClick={onEditClick}
        >
          Edit
        </Button>
      </div>

      <TimeEditorModal
        hours={hours + days * 24}
        minutes={minutes}
        seconds={seconds}
        onSave={onTimeEditorSaveClick}
        onCancel={onTimeEditorCancelClick}
      />

      {timerIsRunning && !onCall && <IdleTimer timeout={EVENT_IDLE_LIMIT_MS} onIdle={onIdle} />}

      <InactivityModal
        open={open}
        loading={timerLoading || eventLoading}
        onResumeClick={onInactivityModalResumeClick}
        onPauseClick={onInactivityModalPauseClick}
        onMoveClick={onInactivityModalMoveClick}
      />
    </div>
  );
};
