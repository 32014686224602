import axios from 'axios';
import { ITimeDto, ITimer, ITimerUpdatePayload } from 'app/models/timer.models';

const basePath = '/time';

export const timerService = {
  getTimer: (eventId: number): Promise<ITimer> => axios.get(`${basePath}/info/${eventId}`),
  pingTimer: (eventId: number): Promise<ITimeDto> => axios.patch(`${basePath}/ping`, { eventId }),
  startTimer: (eventId: number): Promise<ITimeDto> => axios.post(`${basePath}/start`, { eventId }),
  stopTimer: (eventId: number): Promise<ITimeDto> => axios.patch(`${basePath}/stop`, { eventId }),
  deleteTimer: (eventId: number): Promise<ITimeDto> => axios.patch(`${basePath}/cancel`, { eventId }),
  updateTimer: (payload: ITimerUpdatePayload): Promise<ITimeDto> => axios.patch(`${basePath}`, payload),
};
