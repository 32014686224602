import React, { FC, useEffect } from 'react';
import { Spin } from 'antd';
import { InfoPanelItem } from 'common/components/InfoPanelItem';
import { ESocketEvent, ETimeFormat, EUserRole } from 'common/const/enum';
import { useAppDispatch } from 'common/hooks/useAppDispatch';
import { useAppSelector } from 'common/hooks/useAppSelector';
import { getFormattedTime } from 'common/helpers/time.helper';
import { useSocket } from 'common/hooks/useSocket';

const InfoPanelComponent: FC = () => {
  const { auth, statistics } = useAppSelector((state) => ({ auth: state.auth, statistics: state.statistics }));
  const { statistics: statisticsDispatch } = useAppDispatch();

  const { data, loading, watchUpdates } = statistics;
  const { getStatistics } = statisticsDispatch;

  const role = auth.data?.access?.rights?.[0];

  useEffect(() => {
    if (role) {
      getStatistics(role);
    }
  }, [role]);

  useSocket(
    ESocketEvent.Events,
    () => {
      if (role && watchUpdates) {
        getStatistics(role);
      }
    },
    [watchUpdates],
  );

  return (
    <Spin spinning={loading}>
      <div className="info-panel">
        {role === EUserRole.Staff ? (
          <InfoPanelItem title="In Queue" value={data?.pendingCount} />
        ) : (
          <InfoPanelItem title="My pending events" value={data?.pendingCount} />
        )}

        <InfoPanelItem
          title={
            <>
              Completed <br />
              for today
            </>
          }
          value={data?.completedTodayCount}
        />

        <InfoPanelItem
          title={
            <>
              Total review time <br />
              for today
            </>
          }
          value={getFormattedTime(data?.totalTimeToday, ETimeFormat.FullTime)}
          colored
        />
      </div>
    </Spin>
  );
};

export const InfoPanel = InfoPanelComponent;
