import React, { FC, useEffect } from 'react';
import { Button, Layout, Spin, message } from 'antd';
import { useParams } from 'react-router-dom';
import { EEventActionType, EEventStatus, EEventStatusLabel, EEventTypeLabel, ESocketEvent } from 'common/const/enum';
import { useAppSelector } from 'common/hooks/useAppSelector';
import { useAppDispatch } from 'common/hooks/useAppDispatch';
import { useSocket } from 'common/hooks/useSocket';
import { useNotification } from 'common/hooks/useNotification';
import { ReactComponent as ArrowDownIcon } from 'app/assets/images/svg/arrow-down.svg';
import { history } from 'app/store';
import { IEvent } from 'app/models/event.models';
import { EventDetails } from 'entities/Events/components/EventDetails';
import { EventSidebar } from 'entities/Events/components/EventSidebar';
import { getEventAvailableOperations } from 'entities/Events/Events.helper';
import { EventRouterHandler } from 'entities/Events/components/EventRouterHandler';
import { EventContent } from 'entities/Events/components/EventContent';

export const Event: FC = () => {
  const { auth, event, patient, timer } = useAppSelector((state) => ({
    auth: state.auth,
    event: state.event,
    patient: state.patient,
    timer: state.timer,
  }));
  const {
    event: eventDispatch,
    patient: patientDispatch,
    timer: timerDispatch,
    physicianCollecton: physicianCollectonDispatch,
    twilio: twilioDispatch,
  } = useAppDispatch();

  const { id } = useParams();
  const { newEventNotificationContext, showNewEventNotification } = useNotification();

  const { data: authData } = auth;
  const { data: eventData, loading: eventLoading } = event;
  const { data: patientData, loading: patientLoading } = patient;
  const { data: timerData, timerIsRunning } = timer;
  const { getEventById, setEventAction, setEvent, clearEvent } = eventDispatch;
  const { getPatientByEventId } = patientDispatch;
  const { getTimer, stopTimer, setTimer } = timerDispatch;
  const { getPhysicianCollecton } = physicianCollectonDispatch;
  const { setTwilioViewVideoCallWidget } = twilioDispatch;

  const eventId = Number(id);
  const currentUserId = authData?.access?.id;
  const role = authData?.access?.rights[0];

  const { blockRouter, canMessage } = getEventAvailableOperations({
    status: eventData?.status,
    type: eventData?.type,
    role,
    currentUserId,
    staffId: eventData?.staffId,
    physicianId: eventData?.physicianId,
  });

  const onBackClick = () => {
    setEventAction(EEventActionType.Back);
    history.back();
  };

  useEffect(() => {
    if (!eventData) {
      getEventById(eventId);
    }
  }, [eventData]);

  useEffect(() => {
    if (eventData && !eventData.blockRequests) {
      getPatientByEventId(eventId);
      getTimer(eventId);

      if (eventData.status === EEventStatus.UnderReview) {
        getPhysicianCollecton();

        if (eventData.videoCallItemId) {
          setTwilioViewVideoCallWidget(true);
        }
      }
    }
  }, [eventData]);

  useEffect(() => {
    return () => {
      clearEvent();
    };
  }, []);

  useSocket(
    ESocketEvent.Events,
    async (event: IEvent) => {
      const { id, status, staffId, physicianId, videoCallItemId } = event;

      if (eventData && id !== eventData.id && status === EEventStatus.Inbox) {
        showNewEventNotification();
      }

      if (eventData && id === eventData.id && (status === EEventStatus.Completed || status === EEventStatus.Inbox)) {
        if (timerIsRunning) {
          const response = await stopTimer({ eventId: id });

          if (response && timerData) {
            setTimer({ ...timerData, time: response });
          }
        }

        if (status === EEventStatus.Completed) {
          message.info('Event is completed');
        }

        setEvent({ ...event, blockRequests: true });
      }

      if (
        eventData &&
        patientData &&
        id === eventData.id &&
        status === EEventStatus.UnderReview &&
        (staffId === currentUserId || physicianId === currentUserId) &&
        videoCallItemId
      ) {
        setTwilioViewVideoCallWidget(true);
      }
    },
    [eventData, patientData, currentUserId, timerData, timerIsRunning],
  );

  if ((!eventData && eventLoading) || (!patientData && patientLoading)) {
    return <Spin className="spin" />;
  }

  if (!eventData || !patientData) {
    return null;
  }

  return (
    <Layout className="container">
      <div className="event">
        <div className="event__navigation">
          <Button className="event__navigation-btn" icon={<ArrowDownIcon />} onClick={onBackClick}>
            Dashboard
          </Button>

          <span>{` - ${EEventTypeLabel[eventData.type]}`}</span>
        </div>

        <div className="event__wrapper">
          <div className="event__container">
            <div className="event__header">
              <div className="event__header-patient-name">{patientData.name}</div>

              <div>
                <div className="event__header-item">
                  <span>Status:</span>

                  <span>{EEventStatusLabel[eventData.status]}</span>
                </div>

                <div className="event__header-item">
                  <span>Event Type:</span>

                  <span>{EEventTypeLabel[eventData.type]}</span>
                </div>
              </div>
            </div>

            <EventDetails patient={patientData} ccdaLink={eventData.ccdaLink} />

            <EventContent
              eventId={eventId}
              patientId={patientData.id}
              conversationId={eventData.conversationId}
              canMessage={canMessage}
            />
          </div>

          <EventSidebar eventId={eventId} patientId={patientData.id} />
        </div>
      </div>

      <EventRouterHandler eventId={eventId} blockRouter={blockRouter} />

      {newEventNotificationContext}
    </Layout>
  );
};
