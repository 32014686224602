import { createModel } from '@rematch/core';
import { IRequestSuccess } from 'app/models/global.models';
import { IRootModel } from 'app/store/slices';

export const global = createModel<IRootModel>()({
  state: {},
  reducers: {},
  effects: () => ({
    onSuccess<T extends IRequestSuccess>(payload: T) {
      if (payload.onSuccess) {
        payload.onSuccess();
      }
    },
  }),
});
