import React, { FC, useEffect, useState } from 'react';
import { Button, Modal, Spin } from 'antd';
import { PlayCircleOutlined, PauseCircleOutlined } from '@ant-design/icons';
import { EVENT_MOVE_TO_QUEUE_LIMIT_MS } from 'common/config';

interface IComponentProps {
  open: boolean;
  loading: boolean;
  onResumeClick: () => void;
  onPauseClick: () => void;
  onMoveClick: () => void;
}

export const InactivityModal: FC<IComponentProps> = ({ open, loading, onResumeClick, onPauseClick, onMoveClick }) => {
  const [time, setTime] = useState<number>(EVENT_MOVE_TO_QUEUE_LIMIT_MS);

  useEffect(() => {
    let myInterval: NodeJS.Timer;

    if (open) {
      myInterval = setInterval(() => {
        setTime((prevTime) => prevTime - 1000);
      }, 1000);
    }

    return () => {
      clearInterval(myInterval);
      setTime(EVENT_MOVE_TO_QUEUE_LIMIT_MS);
    };
  }, [open]);

  useEffect(() => {
    if (time === 0) {
      onMoveClick?.();
    }
  }, [time]);

  return (
    <Modal
      className="modal inactivity-modal"
      open={open}
      footer={false}
      title="The timer is stopped"
      width={586}
      closable={false}
    >
      <Spin spinning={loading}>
        <div className="modal__body inactivity-modal__body">
          <span>We stopped the timer due to non interaction.</span>
          <span>Are you still reviewing?</span>
        </div>

        <div className="modal__footer">
          <Button className="btn blue" icon={<PlayCircleOutlined />} onClick={onResumeClick}>
            Resume
          </Button>

          <Button className="btn orange" icon={<PauseCircleOutlined />} onClick={onPauseClick}>
            Pause
          </Button>

          <Button className="btn default" onClick={onMoveClick}>
            {`Move event to queue (${time / 1000})`}
          </Button>
        </div>
      </Spin>
    </Modal>
  );
};
