import { RematchDispatch } from '@rematch/core';
import { saveCreds } from 'common/helpers/axios.helper';
import { IRootModel } from 'app/store/slices';
import { IAuthResponse } from 'app/models/auth.models';
import { refreshTokenExpiration } from 'entities/Auth/Auth.const';

export const saveAuthCreds = (dispatch: RematchDispatch<IRootModel>, response: IAuthResponse) => {
  const now = Math.round(Date.now() / 1000);

  const creds = {
    access: {
      id: response.id,
      token: response.access_token,
      issuedAt: now,
      expiredAt: response.expires_in ? now + Number(response.expires_in) : 0,
      rights: response.rights,
      name: response.name,
      tokenType: response.token_type,
    },
    refresh: {
      id: response.id,
      token: String(response.refresh_token),
      userId: response.id,
      issuedAt: now,
      expiredAt: now + refreshTokenExpiration,
    },
  };

  saveCreds(creds);
  dispatch.auth.setAuth(creds);
};
