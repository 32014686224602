import { createModel } from '@rematch/core';
import { axiosErrorHandler } from 'common/helpers/axios.helper';
import { IRootModel } from 'app/store/slices';
import { IHistoryCollectionDto, IHistoryCollectionState } from 'app/models/history.models';
import { historyService } from 'app/store//services/history.service';

export const history = createModel<IRootModel>()({
  state: {
    data: null,
    loading: false,
  } as IHistoryCollectionState,
  reducers: {
    setHistory: (state, payload: IHistoryCollectionDto) => ({ ...state, data: payload }),
    setHistoryLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
  },
  effects: (dispatch) => ({
    async getHistory(eventId: number) {
      dispatch.history.setHistoryLoading(true);

      await historyService
        .getHistory(eventId)
        .then((response) => {
          dispatch.history.setHistory(response);
        })
        .catch(axiosErrorHandler)
        .finally(() => {
          dispatch.history.setHistoryLoading(false);
        });
    },
  }),
});
