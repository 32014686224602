import { createModel } from '@rematch/core';
import { axiosErrorHandler } from 'common/helpers/axios.helper';
import { IRootModel } from 'app/store/slices';
import { IAssessmentHistory, IAssessmentHistoryCollectionParams, IPatientMetricsState } from 'app/models/patientMetrics.models';
import { patientMetricsService } from 'app/store/services/patientMetrics.service';

export const patientMetrics = createModel<IRootModel>()({
  state: {
    assessmentHistoryCollection: [],
    assessmentHistoryCollectionCount: 0,
    loading: false,
  } as IPatientMetricsState,
  reducers: {
    setAssessmentHistoryCollection: (state, payload: IAssessmentHistory[]) => ({
      ...state,
      assessmentHistoryCollection: payload,
    }),
    setAssessmentHistoryCollectionCount: (state, payload: number) => ({
      ...state,
      assessmentHistoryCollectionCount: payload,
    }),
    setAssessmentHistoryCollectionLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
  },
  effects: (dispatch) => ({
    async getAssessmentHistory(params: IAssessmentHistoryCollectionParams) {
      dispatch.patientMetrics.setAssessmentHistoryCollectionLoading(true);

      await patientMetricsService
        .getAssessmentHistory(params)
        .then((response) => {
          dispatch.patientMetrics.setAssessmentHistoryCollection(response.data);
          dispatch.patientMetrics.setAssessmentHistoryCollectionCount(response.meta.count);
        })
        .catch(axiosErrorHandler)
        .finally(() => {
          dispatch.patientMetrics.setAssessmentHistoryCollectionLoading(false);
        });
    },
  }),
});
