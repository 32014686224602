import { useEffect } from 'react';
import { fetchEventSource } from '@microsoft/fetch-event-source';
import { useAppSelector } from 'common/hooks/useAppSelector';
class FatalError extends Error {}

export const useSse = (onMessageCallback: (json: string) => void, fetchMessages: () => void) => {
  const { auth } = useAppSelector((state) => ({ auth: state.auth }));
  const token = auth.data?.access?.token;

  useEffect(() => {
    const controller = new AbortController();
    const fetchData = async () => {
      await fetchEventSource(`/api/sse`, {
        method: 'GET',
        headers: {
          Accept: 'text/event-stream',
          Authorization: `Bearer ${token}`,
        },
        signal: controller.signal,
        openWhenHidden: true,
        onmessage(event) {
          onMessageCallback(event.data);
        },
        onerror(error) {
          if (error instanceof FatalError) {
            fetchMessages();
            throw error;
          }
        },
      });
    };

    fetchData();
    return () => controller.abort();
  }, []);
};
