import type { MenuProps } from 'antd';
import { EEventHistoryMenuKey } from 'common/const/enum';

export const eventHistoryMenuItems: MenuProps['items'] = [
  {
    label: 'Notes',
    key: EEventHistoryMenuKey.Notes,
  },
  {
    label: 'Event History',
    key: EEventHistoryMenuKey.EventHistory,
  },
  {
    label: 'All',
    key: EEventHistoryMenuKey.All,
  },
];
