import React, { FC } from 'react';

interface IComponentProps {
  title: React.ReactNode;
  value?: string | number;
  colored?: boolean;
}

const InfoPanelItemComponent: FC<IComponentProps> = ({ title, value, colored }) => {
  return (
    <div className={`info-panel-item ${colored ? 'info-panel-item-colored' : ''}`}>
      <div className="info-panel-item__title">{title}</div>

      <div className="info-panel-item__value">{value}</div>
    </div>
  );
};

export const InfoPanelItem = InfoPanelItemComponent;
