import React, { ChangeEvent, FC, useMemo, useRef, useState, KeyboardEvent } from 'react';
import { Button, Input, Spin } from 'antd';
import dayjs from 'dayjs';
import { useAppDispatch } from 'common/hooks/useAppDispatch';
import { useAppSelector } from 'common/hooks/useAppSelector';
import { EDateFormat } from 'common/const/enum';
import { useScroll } from 'common/hooks/useScroll';
import { IMessage } from 'app/models/message.models';
import { groupMessagesByDate, renderMessageItem } from 'entities/Messages/Messages.helper';

interface IComponentProps {
  eventId: number;
  canMessage: boolean;
  messages: IMessage[];
  loading: boolean;
}

export const Messages: FC<IComponentProps> = ({ eventId, canMessage, messages, loading }) => {
  const { auth, message } = useAppSelector((state) => ({ auth: state.auth, message: state.message }));
  const { message: messageDispatch } = useAppDispatch();

  const [messageValue, setMessageValue] = useState<string>('');
  const ref = useRef<HTMLDivElement>(null);

  const { sendMessage } = messageDispatch;

  const currentUsername = auth.data?.access?.name;
  const groupedMessages = useMemo(() => groupMessagesByDate(messages), [messages]);

  const onMessageChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setMessageValue(e.target.value);
  };

  const onMessageFieldKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault();
      onSendClick();
    }
  };

  const onSendClick = () => {
    sendMessage({ eventId, message: messageValue });
    setMessageValue('');
  };

  useScroll(ref, [messages]);

  return (
    <div className="messages">
      <Spin spinning={loading}>
        <div ref={ref} className="messages__container">
          <div className="messages__scroll-container">
            {groupedMessages.length ? (
              groupedMessages.map((groupedItem) => {
                return (
                  <div key={groupedItem.date} className="messages__block">
                    <div className="messages__block-date">{dayjs(groupedItem.date).format(EDateFormat.DayFullMonth)}</div>

                    {groupedItem.items.map((item) => renderMessageItem(item, currentUsername))}
                  </div>
                );
              })
            ) : (
              <div className="messages__block">
                <div className="messages__block-date">No chat history yet</div>
              </div>
            )}
          </div>
        </div>

        <Spin spinning={message.loading}>
          <div className="messages__footer">
            <Input.TextArea
              rows={3}
              placeholder="Message"
              value={messageValue}
              onChange={onMessageChange}
              onKeyDown={onMessageFieldKeyDown}
              disabled={!canMessage}
            />

            <Button className="btn blue" onClick={onSendClick} disabled={!canMessage || !messageValue.length}>
              Send
            </Button>
          </div>
        </Spin>
      </Spin>
    </div>
  );
};
