import React, { FC } from 'react';
import { useAppSelector } from 'common/hooks/useAppSelector';
import { useAppDispatch } from 'common/hooks/useAppDispatch';
import { VoiceCall } from 'entities/Calls/components/VoiceCall/VoiceCall';
import { VoiceCallActive } from 'entities/Calls/components/VoiceCall/VoiceCallActive';
import { getEventAvailableOperations } from 'entities/Events/Events.helper';

interface IComponentProps {
  eventId: number;
}

export const VoiceCallWrapper: FC<IComponentProps> = ({ eventId }) => {
  const { auth, event, timer, twilio } = useAppSelector((state) => ({
    auth: state.auth,
    event: state.event,
    timer: state.timer,
    twilio: state.twilio,
  }));
  const { timer: timerDispatch, twilio: twilioDispatch } = useAppDispatch();

  const { data: authData } = auth;
  const { data: eventData } = event;
  const { timerIsRunning } = timer;
  const { voiceToken, loading, onCall, duration } = twilio;
  const { startTimer } = timerDispatch;
  const { getVoiceCallToken, setTwilioOnCall, setTwilioDuration, trackVoiceCallDuration, resetTwilioData } = twilioDispatch;

  const currentUserId = authData?.access?.id;
  const role = authData?.access?.rights[0];
  const phone = eventData?.lucyPhone ? eventData?.lucyPhone : eventData?.phone;
  const { canStart } = getEventAvailableOperations({
    status: eventData?.status,
    type: eventData?.type,
    role,
    currentUserId,
    staffId: eventData?.staffId,
    physicianId: eventData?.physicianId,
  });

  const onCallClick = () => {
    if (canStart && !timerIsRunning) {
      startTimer({
        eventId,
        onSuccess: () => getVoiceCallToken(),
      });
    } else {
      getVoiceCallToken();
    }
  };

  const onEndCallClick = () => {
    if (onCall && duration) {
      trackVoiceCallDuration({ eventId, duration });
    }

    resetTwilioData();
  };

  return voiceToken ? (
    <VoiceCallActive
      token={voiceToken}
      phone={phone}
      onCall={onCall}
      setOnCall={setTwilioOnCall}
      setDuration={setTwilioDuration}
      onEndCallClick={onEndCallClick}
    />
  ) : (
    <VoiceCall phone={phone} loading={loading} onCall={onCall} onCallClick={onCallClick} />
  );
};
