import { useEffect } from 'react';
import { SocketIOService } from 'common/SocketIoService';

export const useSocket = (event: string, callback: (message?: any) => void, depth: any[] = []) => {
  const socket = SocketIOService.getSocket();

  useEffect(() => {
    socket?.on(event, (message) => {
      callback(message);
    });

    return () => {
      socket?.off(event);
    };
  }, [...depth]);
};
