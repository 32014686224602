import React, { FC, useEffect, useState } from 'react';
import { Spin, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useAppDispatch } from 'common/hooks/useAppDispatch';
import { useAppSelector } from 'common/hooks/useAppSelector';
import { EventTooltipItem } from 'entities/Events/components/EventTooltipItem';

interface IComponentProps {
  assessmentId?: string;
}

// TODO remove any
export const EventTooltip: FC<IComponentProps> = ({ assessmentId }) => {
  const { assessments } = useAppSelector((state) => ({ assessments: state.assessmentCollection }));
  const { assessmentCollection: assessmentCollectionDispatch } = useAppDispatch();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [assessment, setAssessment] = useState<any>({});

  const { data, loading } = assessments;
  const { getAssessmentById } = assessmentCollectionDispatch;

  useEffect(() => {
    if (isOpen && assessmentId) {
      const assessment = data[assessmentId];

      if (!assessment) {
        getAssessmentById(assessmentId);
      } else {
        setAssessment(assessment);
      }
    }
  }, [isOpen, assessmentId, data]);

  return (
    <Tooltip
      overlayClassName="tooltip"
      onOpenChange={setIsOpen}
      title={
        <div>
          {loading ? (
            <Spin size="small" />
          ) : (
            assessment.answers?.map((answer: any, index: number) => {
              if (index === 0) {
                return null;
              }

              return <EventTooltipItem key={answer.id} assessment={assessment} answer={answer} index={index} />;
            })
          )}
        </div>
      }
    >
      <InfoCircleOutlined className="events-list__table-item-check-in" />
    </Tooltip>
  );
};
