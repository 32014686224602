import { createModel } from '@rematch/core';
import { axiosErrorHandler } from 'common/helpers/axios.helper';
import { IRootModel } from 'app/store/slices';
import { IAssessmentCollectionState, IAssessmentSetPayload } from 'app/models/assessment.models';
import { assessmentService } from 'app/store/services/assessment.service';

export const assessmentCollection = createModel<IRootModel>()({
  state: {
    data: {},
    loading: false,
  } as IAssessmentCollectionState,
  reducers: {
    setAssessment: (state, payload: IAssessmentSetPayload) => ({
      ...state,
      data: {
        ...state.data,
        [payload.assessmentId]: payload.assessment,
      },
    }),
    setAssessmentLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
  },
  effects: (dispatch) => ({
    async getAssessmentById(assessmentId: string) {
      dispatch.assessmentCollection.setAssessmentLoading(true);

      await assessmentService
        .getAssessmentById(assessmentId)
        .then((response) => {
          dispatch.assessmentCollection.setAssessment({ assessmentId, assessment: response });
        })
        .catch(axiosErrorHandler)
        .finally(() => {
          dispatch.assessmentCollection.setAssessmentLoading(false);
        });
    },
  }),
});
