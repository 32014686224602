import React, { FC, useEffect } from 'react';
import Layout from 'antd/es/layout/layout';
import { Route, Routes } from 'react-router-dom';
import { Header } from 'common/components/Header';
import { PrivateLayout } from 'common/components/PrivateLayout';
import { ERoute } from 'common/const/enum';
import { useAppDispatch } from 'common/hooks/useAppDispatch';
import { Login } from 'app/pages/Login';

export const App: FC = () => {
  const { auth: authDispatch } = useAppDispatch();
  const { initAuth } = authDispatch;

  useEffect(() => {
    initAuth();
  }, []);

  return (
    <Layout>
      <Header />

      <Routes>
        <Route path={ERoute.Login} element={<Login />} />
        <Route path="*" element={<PrivateLayout />} />
      </Routes>
    </Layout>
  );
};
