import { EQuestionType } from 'common/const/enum';
import { convertibleKeyCodes } from 'entities/Assessment/Assessment.const';

// TODO remove any
export const assessmentQuestionAnswerToKeyCodes = (answer: any, index: number) => {
  const codes: string[] = [];

  switch (answer.question.type) {
    case EQuestionType.multipleChoice: {
      answer.values.forEach((value: any) => {
        codes.push(convertibleKeyCodes[value.value.value]);
      });
      break;
    }
    case EQuestionType.textOrSkip: {
      answer.values.forEach((value: any) => {
        if (value.value.checkbox) {
          codes.push(convertibleKeyCodes[answer.question.view.checkboxText]);
        } else {
          codes.push(value.value.value);
        }
      });
      break;
    }
    default: {
      break;
    }
  }

  return `${index}. ${codes.join(' ')}`;
};
