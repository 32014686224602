import React, { FC, useEffect, useState } from 'react';
import { Button } from 'antd';
import { RemoteParticipant, Room } from 'twilio-video';
import { useAppDispatch } from 'common/hooks/useAppDispatch';
import { ReactComponent as PhoneIcon } from 'app/assets/images/svg/phone.svg';
import { ReactComponent as MicrophoneIcon } from 'app/assets/images/svg/microphone-filled.svg';
import { ReactComponent as VideoIcon } from 'app/assets/images/svg/video-camera-filled.svg';
import { IParticipantDetails } from 'app/models/twilio.models';
import logo from 'app/assets/images/logo.png';
import { Participant } from 'entities/Calls/components/VideoCall/Participant';

interface IComponentProps {
  room: Room;
  onEndCallClick: () => void;
}

export const VideoRoom: FC<IComponentProps> = ({ room, onEndCallClick }) => {
  const { twilio: twilioDispatch } = useAppDispatch();

  const [participants, setParticipants] = useState<RemoteParticipant[]>([]);
  const [participantsDetails, setParticipantsDetails] = useState<IParticipantDetails[]>([]);
  const [localVideoDisabled, setLocalVideoDisabled] = useState<boolean>(false);
  const [localAudioDisabled, setLocalAudioDisabled] = useState<boolean>(false);

  const { getParticipantByIdentity } = twilioDispatch;

  const toggleLocalAudioMute = () => {
    room.localParticipant.audioTracks.forEach((trackPublication) => {
      const track = trackPublication.track;

      if (localAudioDisabled) {
        track.enable();
        setLocalAudioDisabled(false);
      } else {
        track.disable();
        setLocalAudioDisabled(true);
      }
    });
  };

  const toggleLocalVideoMute = () => {
    room.localParticipant.videoTracks.forEach((trackPublication) => {
      const track = trackPublication.track;

      if (localVideoDisabled) {
        track.enable();
        setLocalVideoDisabled(false);
      } else {
        track.disable();
        setLocalVideoDisabled(true);
      }
    });
  };

  const toggleRemoteAudioMute = (sid: string, muted: boolean) => {
    setParticipantsDetails((prev) => {
      const participant = prev.find((item) => item.sid === sid);

      if (participant) {
        participant.audioMuted = muted;
        return [...prev];
      } else {
        return prev;
      }
    });
  };

  const toggleRemoteVideoMute = (sid: string, muted: boolean) => {
    setParticipantsDetails((prev) => {
      const participant = prev.find((item) => item.sid === sid);

      if (participant) {
        participant.videoMuted = muted;
        return [...prev];
      } else {
        return prev;
      }
    });
  };

  useEffect(() => {
    const participantConnected = (participant: RemoteParticipant) => {
      getParticipantByIdentity(participant.identity).then((response) => {
        if (response) {
          setParticipantsDetails((prev) => [...prev, { ...response, sid: participant.sid }]);
          setParticipants((prev) => [...prev, participant]);
        }
      });
    };

    const participantDisconnected = (participant: RemoteParticipant) => {
      setParticipantsDetails((prev) => prev.filter((item) => item.sid !== participant.sid));
      setParticipants((prev) => prev.filter((item) => item !== participant));
    };

    room.on('participantConnected', participantConnected);
    room.on('participantDisconnected', participantDisconnected);
    room.participants.forEach(participantConnected);
  }, []);

  return (
    <div className="video-room">
      <div className="video-room__header">
        {participantsDetails.map(({ name, sid }) => {
          return (
            <div key={sid} className="video-room__header-participant-name">
              {name}
            </div>
          );
        })}
      </div>

      <div className="video-room__participants">
        <div className="video-room__participants-screensaver">
          <img src={logo} alt="Manifesto health" />
        </div>

        {participants.map((participant, index) => {
          return (
            <Participant
              key={participant.sid}
              participant={participant}
              participantType={participantsDetails[index].type}
              toggleAudioMute={toggleRemoteAudioMute}
              toggleVideoMute={toggleRemoteVideoMute}
            />
          );
        })}
      </div>

      <Participant
        participant={room.localParticipant}
        participantType="localParticipant"
        toggleAudioMute={toggleRemoteAudioMute}
        toggleVideoMute={toggleRemoteVideoMute}
      />

      <div className="video-room__container">
        <Button
          className={`btn default icon ${localAudioDisabled ? 'audio-disabled' : ''}`}
          icon={<MicrophoneIcon />}
          onClick={toggleLocalAudioMute}
        />

        <Button className="btn red video-room__end-call-btn" icon={<PhoneIcon />} onClick={onEndCallClick}>
          End call
        </Button>

        <Button
          className={`btn default icon ${localVideoDisabled ? 'video-disabled' : ''}`}
          icon={<VideoIcon />}
          onClick={toggleLocalVideoMute}
        />
      </div>
    </div>
  );
};
