import axios, { AxiosError, AxiosResponse } from 'axios';
import { message } from 'antd';
import { EErrorStatus } from 'common/const/enum';
import store from 'app/store';
import { IToken } from 'app/models/auth.models';
import { IAxiosResponseData } from 'app/models/global.models';

export const getCreds = async (): Promise<IToken | null> => {
  return new Promise((resolve) => {
    const creds = localStorage.getItem('creds');
    resolve(creds ? JSON.parse(creds) : null);
  });
};

export const saveCreds = (creds: IToken): void => {
  localStorage.setItem('creds', JSON.stringify(creds));
};

export const clearCreds = (): void => {
  localStorage.removeItem('creds');
};

let isRefreshing = false;
let refreshPromise: Promise<string | void> | null = null;

export const initAxios = () => {
  axios.defaults.baseURL = `/api`;
  axios.interceptors.request.use(async (config) => {
    const creds = await getCreds();

    if (creds && creds.access) {
      config.headers.Authorization = `Bearer ${creds.access.token}`;
    }

    return config;
  });

  axios.interceptors.response.use(
    (response: AxiosResponse) => response.data,
    async (error) => {
      if (error.response && error.response.status === 401 && !error.config.url?.includes('/auth')) {
        const creds = await getCreds();

        if (!isRefreshing && creds && creds.refresh) {
          isRefreshing = true;
          refreshPromise = store.dispatch.auth.refreshToken(creds.refresh.token);
        }

        return await refreshPromise?.then((token) => {
          isRefreshing = false;
          refreshPromise = null;

          if (token) {
            error.config.headers['Authorization'] = `Bearer ${token}`;
            return axios.request(error.config);
          }

          return Promise.reject(error);
        });
      }

      if (error.response && error.response.status === 403 && error.config.url?.includes('/auth')) {
        clearCreds();
        store.dispatch.auth.clearAuth();
        return Promise.resolve();
      }

      return Promise.reject(error);
    },
  );
};

export const axiosErrorHandler = (error: AxiosError, setError?: (payload: string) => void) => {
  const response = error.response as AxiosResponse<IAxiosResponseData | undefined>;

  if (response) {
    const { data, status, statusText } = response;

    if (data) {
      if (setError && status !== EErrorStatus.InternalServerError) {
        setError(data.message);
      } else {
        message.error(data.message || data.error);
      }
    } else {
      message.error(statusText);
    }
  } else {
    message.error('Unexpected error');
  }
};
