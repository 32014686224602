import React, { FC } from 'react';
import parse from 'html-react-parser';
import dayjs from 'dayjs';
import { EDateFormat } from 'common/const/enum';
import { INoteDto } from 'app/models/note.models';

interface IComponentProps {
  note: INoteDto;
}

export const HistoryNote: FC<IComponentProps> = ({ note }) => {
  return (
    <div className="history__item">
      <div className="history__item-header">
        <span>{dayjs(note.createdAt).format(EDateFormat.FullDateTime12Slash)}</span>

        <span>{`by: ${note.userName}`}</span>
      </div>

      <div className="history__item-message note">{parse(note.note)}</div>
    </div>
  );
};
