import React, { FC, useEffect, useRef, useState } from 'react';
import { LocalParticipant, RemoteAudioTrack, RemoteParticipant, RemoteTrack, RemoteVideoTrack } from 'twilio-video';
import logo from 'app/assets/images/logo.png';
import { trackpubsToTracks } from 'entities/Calls/Calls.helper';

interface IComponentProps {
  participant: RemoteParticipant | LocalParticipant;
  participantType: string;
  toggleAudioMute: (sid: string, muted: boolean) => void;
  toggleVideoMute: (sid: string, muted: boolean) => void;
}

export const Participant: FC<IComponentProps> = (props) => {
  const { participant, participantType, toggleAudioMute, toggleVideoMute } = props;

  const [videoTracks, setVideoTracks] = useState<RemoteVideoTrack[]>([]);
  const [audioTracks, setAudioTracks] = useState<RemoteAudioTrack[]>([]);

  const isEnabled = videoTracks[0]?.isEnabled;
  const isLocalParticipant = participantType === 'localParticipant';

  const videoRef = useRef<HTMLVideoElement | null>(null);
  const audioRef = useRef<HTMLAudioElement | null>(null);

  useEffect(() => {
    const trackSubscribed = (track: RemoteTrack) => {
      if (track.kind === 'video') {
        setVideoTracks((prev) => [...prev, track]);
      } else if (track.kind === 'audio') {
        setAudioTracks((prev) => [...prev, track]);
      }
    };

    const trackUnsubscribed = (track: RemoteTrack) => {
      if (track.kind === 'video') {
        setVideoTracks((prev) => prev.filter((item) => item !== track));
      } else if (track.kind === 'audio') {
        setAudioTracks((prev) => prev.filter((item) => item !== track));
      }
    };

    setVideoTracks(trackpubsToTracks(participant.videoTracks) as RemoteVideoTrack[]);
    setAudioTracks(trackpubsToTracks(participant.audioTracks) as RemoteAudioTrack[]);

    participant.on('trackSubscribed', trackSubscribed);
    participant.on('trackUnsubscribed', trackUnsubscribed);

    return () => {
      setVideoTracks([]);
      setAudioTracks([]);
      participant.removeAllListeners();
    };
  }, [participant]);

  useEffect(() => {
    const videoTrack = videoTracks[0];

    videoTrack?.attach(videoRef.current as HTMLVideoElement);

    videoTrack?.on('disabled', () => {
      toggleVideoMute(participant.sid, true);
    });

    videoTrack?.on('enabled', () => {
      toggleVideoMute(participant.sid, false);
    });

    return () => {
      videoTrack?.detach();
    };
  }, [videoTracks]);

  useEffect(() => {
    const audioTrack = audioTracks[0];

    audioTrack?.attach(audioRef.current as HTMLAudioElement);

    audioTrack?.on('disabled', () => {
      toggleAudioMute(participant.sid, true);
    });

    audioTrack?.on('enabled', () => {
      toggleAudioMute(participant.sid, false);
    });

    return () => {
      audioTrack?.detach();
    };
  }, [audioTracks]);

  return (
    <>
      {participantType === 'patient' && (
        <div className="participant">
          <div className="participant__screensaver">
            <img src={logo} alt="Manifesto health" />
          </div>

          <video className={`participant__video ${isEnabled ? 'isEnabled' : ''}`} ref={videoRef} autoPlay={true} />
        </div>
      )}

      {isLocalParticipant && (
        <div className="local-participant">
          <video className={`local-participant__video ${isEnabled ? 'isEnabled' : ''}`} ref={videoRef} autoPlay={true} />
        </div>
      )}

      <audio ref={audioRef} autoPlay={true} muted={false} />
    </>
  );
};
