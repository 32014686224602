import React, { FC, useState } from 'react';
import { Button, Spin } from 'antd';
import { useAppSelector } from 'common/hooks/useAppSelector';
import { useAppDispatch } from 'common/hooks/useAppDispatch';
import { TextEditor } from 'entities/Notes/components/TextEditor';

interface IComponentProps {
  eventId: number;
}

export const Notes: FC<IComponentProps> = ({ eventId }) => {
  const { note } = useAppSelector((state) => ({ note: state.note }));
  const { note: noteDispatch } = useAppDispatch();

  const [value, setValue] = useState<string>('');

  const { loading } = note;
  const { createNote } = noteDispatch;

  const onSaveClick = () => {
    createNote({
      eventId,
      note: value,
      onSuccess: () => setValue(''),
    });
  };

  const onDiscardClick = () => {
    setValue('');
  };

  return (
    <div className="notes">
      <Spin wrapperClassName="notes__spin" spinning={loading}>
        <div className="notes__title">Notes</div>

        <TextEditor value={value} onChange={setValue} />

        <div className="notes__container">
          <Button className="btn blue" onClick={onSaveClick}>
            Save
          </Button>

          <Button className="btn red" onClick={onDiscardClick}>
            Discard
          </Button>
        </div>
      </Spin>
    </div>
  );
};
