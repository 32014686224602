import React from 'react';
import type { ColumnsType } from 'antd/es/table';
import { IAssessmentHistory } from 'app/models/patientMetrics.models';

export const renderPromisRecords = () => {
  return [
    {
      title: 'Instrument',
      dataIndex: 'instrument',
    },
    {
      title: 'Score',
      dataIndex: 'score',
    },
    {
      title: 'Severity',
      dataIndex: 'severity',
    },
    {
      title: 'Percentile',
      dataIndex: 'percentile',
    },
    // TODO
  ] as ColumnsType<any>;
};

export const renderAssessmentHistoryRecords = () => {
  return [
    {
      title: 'Sent Date',
      dataIndex: 'sentDate',
    },
    {
      title: 'Report Date',
      dataIndex: 'reportDate',
    },
    {
      title: 'Assessment',
      dataIndex: 'assessmentName',
    },
    {
      title: 'Percent Complete',
      dataIndex: 'percentComplete',
      render: (text) => `${text}%`,
    },
    {
      title: 'Actions',
      dataIndex: 'reportUrl',
      render: (text: string) =>
        text && (
          <a href={text} target="_blank" rel="noreferrer">
            View Report
          </a>
        ),
      width: '114px',
    },
  ] as ColumnsType<IAssessmentHistory>;
};
