import React, { FC } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Spin } from 'antd';
import { ERoute } from 'common/const/enum';
import { useAppSelector } from 'common/hooks/useAppSelector';
import { Dashboard } from 'app/pages/Dashboard';
import { Event } from 'app/pages/Event';

export const PrivateLayout: FC = () => {
  const { auth } = useAppSelector((state) => ({ auth: state.auth }));
  const { data, loading } = auth;

  if (!data && !loading) {
    return <Navigate to={ERoute.Login} />;
  }

  if (!data && loading) {
    return <Spin className="spin" spinning />;
  }

  return (
    <Spin spinning={loading}>
      <Routes>
        <Route path={ERoute.Dashboard} element={<Dashboard />} />
        <Route path={ERoute.Event} element={<Event />} />
        <Route path="*" element={<Navigate to={ERoute.Dashboard} />} />
      </Routes>
    </Spin>
  );
};
