import { createModel } from '@rematch/core';
import { axiosErrorHandler } from 'common/helpers/axios.helper';
import { IRootModel } from 'app/store/slices';
import {
  INoteCollectionDto,
  INoteCollectionParams,
  INoteCollectionState,
  INoteCreatePayload,
  INoteDto,
  INoteState,
} from 'app/models/note.models';
import { noteService } from 'app/store/services/note.service';

export const noteCollection = createModel<IRootModel>()({
  state: {
    data: null,
    loading: false,
  } as INoteCollectionState,
  reducers: {
    setNoteCollection: (state, payload: INoteCollectionDto) => ({ ...state, data: payload }),
    setNoteCollectionLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
  },
  effects: (dispatch) => ({
    async getNotes(params: INoteCollectionParams) {
      dispatch.noteCollection.setNoteCollectionLoading(true);

      await noteService
        .getNotes(params)
        .then((response) => {
          dispatch.noteCollection.setNoteCollection(response);
        })
        .catch(axiosErrorHandler)
        .finally(() => {
          dispatch.noteCollection.setNoteCollectionLoading(false);
        });
    },
  }),
});

export const note = createModel<IRootModel>()({
  state: {
    data: null,
    loading: false,
  } as INoteState,
  reducers: {
    setNote: (state, payload: INoteDto) => ({ ...state, data: payload }),
    setNoteLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
  },
  effects: (dispatch) => ({
    async createNote(payload: INoteCreatePayload) {
      dispatch.note.setNoteLoading(true);

      await noteService
        .createNote(payload)
        .then((response) => {
          dispatch.note.setNote(response);
          dispatch.global.onSuccess(payload);
          dispatch.noteCollection.getNotes({ eventId: payload.eventId.toString() });
        })
        .catch(axiosErrorHandler)
        .finally(() => {
          dispatch.note.setNoteLoading(false);
        });
    },
  }),
});
