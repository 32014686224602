import React from 'react';
import type { ColumnsType } from 'antd/es/table';
import { Badge, type MenuProps } from 'antd';
import dayjs from 'dayjs';
import {
  EDateFormat,
  EEventMenuKey,
  EEventStatus,
  EEventType,
  EEventTypeLabel,
  EOrderDirection,
  EOrderDirectionShort,
  ETimeFormat,
  EUserRole,
} from 'common/const/enum';
import { getFormattedTime } from 'common/helpers/time.helper';
import { TABLE_LIMIT } from 'common/config';
import { formatPhoneNumber } from 'common/helpers/phone.helper';
import { EventCollectionOrderField, IEventAvailableOperationsParams, IEventListItem } from 'app/models/event.models';
import { IPatient } from 'app/models/patient.models';
import { EventTooltip } from 'entities/Events/components/EventTooltip';

export const renderEventListRecords = (status: EEventStatus) => {
  return [
    {
      title: 'Date',
      dataIndex: 'createdAt',
      sorter: true,
      render: (text) => dayjs(text).format(EDateFormat.DayShortMonthTime12),
    },
    {
      title: 'Patient name',
      dataIndex: 'patientName',
      sorter: true,
    },
    {
      title: 'Type',
      dataIndex: 'type',
      sorter: true,
      render: (text: EEventType) => EEventTypeLabel[text],
    },
    {
      title: 'Last Message',
      dataIndex: 'lastMessage',
      ellipsis: true,
    },
    {
      title: 'Last Provider',
      dataIndex: 'providerName',
    },
    {
      title: 'Event Age',
      dataIndex: 'createdAt',
      render: (text, record) => {
        if (status === EEventStatus.Completed) {
          return getFormattedTime(dayjs(record.completedAt).diff(record.createdAt), ETimeFormat.TimeWithUnit);
        } else {
          return getFormattedTime(dayjs().diff(text), ETimeFormat.TimeWithUnit);
        }
      },
    },
    {
      title: 'Check-In',
      dataIndex: 'lastAssessmentId',
      render: (_, record) => <EventTooltip assessmentId={record.lastAssessmentId} />,
      width: 112,
    },
  ] as ColumnsType<IEventListItem>;
};

export const toPatientDetails = (patient: IPatient) => {
  return {
    gender: patient.gender,
    age: dayjs().diff(dayjs(patient.dob), 'year'),
    dob: dayjs(patient.dob).format(EDateFormat.FullDateShortSlash),
    language: patient.language,
    phone: formatPhoneNumber(patient.phone),
  };
};

export const getEventCollectionPayload = (
  page: number,
  eventStatus: EEventStatus,
  orderField: string,
  currentOrderDirection?: EOrderDirection,
  role?: string,
) => {
  let orderDirection = undefined;
  let status = undefined;
  let isEscalated = undefined;
  let hasPhysicianReviewer = undefined;

  if (currentOrderDirection) {
    orderDirection = currentOrderDirection === EOrderDirection.Ascend ? EOrderDirectionShort.ASC : EOrderDirectionShort.DESC;
  }

  if (role === EUserRole.Staff) {
    if (eventStatus === EEventStatus.UnderReview) {
      status = EEventStatus.UnderReview;
      isEscalated = false;
    } else if (eventStatus === EEventStatus.Escalated) {
      status = EEventStatus.UnderReview;
      isEscalated = true;
    } else {
      status = eventStatus;
    }
  }

  if (role === EUserRole.Physician) {
    if (eventStatus === EEventStatus.Inbox) {
      status = EEventStatus.UnderReview;
      isEscalated = true;
    } else if (eventStatus === EEventStatus.UnderReview) {
      status = EEventStatus.UnderReview;
      isEscalated = true;
      hasPhysicianReviewer = true;
    } else {
      status = eventStatus;
    }
  }

  return {
    offset: TABLE_LIMIT * (page - 1),
    limit: TABLE_LIMIT,
    orderDirection,
    isEscalated,
    status,
    orderField: orderField as EventCollectionOrderField,
    hasPhysicianReviewer,
  };
};

export const getEventAvailableOperations = (params: IEventAvailableOperationsParams) => {
  const { status, type, role, currentUserId, staffId, physicianId } = params;

  let canReview = false;
  let canStart = false;
  let autoStart = false;
  let blockRouter = false;
  let canBeEscalated = false;
  let canBeUnescalated = false;
  let canBeCompleted = false;
  let canCall = false;
  let canMessage = false;

  if (status === EEventStatus.Inbox) {
    canReview = true;
  }

  if (role === EUserRole.Physician && status === EEventStatus.UnderReview && !physicianId) {
    canReview = true;
  }

  if (role === EUserRole.Staff && (currentUserId === staffId || !staffId) && status === EEventStatus.UnderReview) {
    canReview = true;
    canStart = true;
    autoStart = true;
    blockRouter = true;
    canBeEscalated = true;
    canBeCompleted = true;
    canMessage = true;
  }

  if (role === EUserRole.Staff && currentUserId === staffId && !physicianId && status === EEventStatus.UnderReview) {
    canBeUnescalated = true;
  }

  if (role === EUserRole.Physician && currentUserId === physicianId && status === EEventStatus.UnderReview) {
    canReview = true;
    canStart = true;
    autoStart = true;
    blockRouter = true;
    canBeEscalated = true;
    canBeUnescalated = true;
    canBeCompleted = true;
    canMessage = true;
  }

  if (role === EUserRole.Staff && currentUserId === staffId && type === EEventType.Lucy) {
    canCall = true;
  }

  if (role === EUserRole.Physician && currentUserId === physicianId && type === EEventType.Lucy) {
    canCall = true;
  }

  return { canReview, canStart, autoStart, blockRouter, canBeEscalated, canBeUnescalated, canBeCompleted, canCall, canMessage };
};

export const getEventMenuItems = (unreadMessages: number, showUnreadMessagesBadge: boolean) => {
  return [
    {
      label: (
        <>
          <span>Messages</span>

          {showUnreadMessagesBadge && <Badge count={unreadMessages} />}
        </>
      ),
      key: EEventMenuKey.Messages,
    },
    {
      label: 'Patient Details',
      key: EEventMenuKey.PatientDetails,
    },
  ] as MenuProps['items'];
};
