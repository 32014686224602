import React, { FC } from 'react';
import { Dropdown, Menu, Space } from 'antd';
import type { MenuProps } from 'antd';
import { useNavigate } from 'react-router-dom';
import { headerNavItems } from 'common/const/header.const';
import { useAppSelector } from 'common/hooks/useAppSelector';
import { useAppDispatch } from 'common/hooks/useAppDispatch';
import { getLogoutDropdownItems } from 'common/helpers/header.helper';
import { ReactComponent as ArrowDownIcon } from 'app/assets/images/svg/arrow-down.svg';

export const Header: FC = () => {
  const { auth } = useAppSelector((state) => ({ auth: state.auth }));
  const { auth: authDispatch } = useAppDispatch();
  const navigate = useNavigate();

  const { data } = auth;
  const { logout } = authDispatch;

  const onNavItemClick: MenuProps['onClick'] = (e) => {
    navigate(e.key);
  };

  return (
    <div className="header">
      {data && (
        <>
          <Menu
            className="header__nav"
            defaultSelectedKeys={['/dashboard']}
            mode="horizontal"
            items={headerNavItems}
            onClick={onNavItemClick}
          />

          <Dropdown className="header__dropdown" menu={{ items: getLogoutDropdownItems(logout) }}>
            <a onClick={(e) => e.preventDefault()}>
              <Space className="header__dropdown-container">
                <span>{data.access?.name}</span>

                <ArrowDownIcon />
              </Space>
            </a>
          </Dropdown>
        </>
      )}
    </div>
  );
};
