import React, { FC } from 'react';
import dayjs from 'dayjs';
import { ETimeFormat } from 'common/const/enum';
import { IMessage } from 'app/models/message.models';

interface IComponentProps {
  item: IMessage;
}

export const LucyType: FC<IComponentProps> = ({ item }) => {
  const { createdAt, patientName } = item;
  const time = dayjs(createdAt).format(ETimeFormat.ShortTime12);

  return (
    <div className="messages__lucy">
      <div className="messages__video-call-avatar">{patientName?.match(/\b(\w)/g)?.join('')}</div>

      <div className="messages__lucy-container">Call back requested</div>

      <div className="messages__lucy-time">{time}</div>
    </div>
  );
};
