export const modules = {
  toolbar: [
    {
      header: '1',
    },
    {
      header: '2',
    },
    'bold',
    'italic',
    'strike',
    'blockquote',
    { list: 'ordered' },
    { list: 'bullet' },
    'link',
    'clean',
  ],
};

export const formats = ['header', 'bold', 'italic', 'strike', 'blockquote', 'list', 'bullet', 'link'];
