import type { MenuProps } from 'antd';

export const getLogoutDropdownItems = (logout: () => void) => {
  return [
    {
      label: 'Log out',
      key: 'logout',
      onClick: logout,
    },
  ] as MenuProps['items'];
};
