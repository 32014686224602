import React, { FC, useEffect, useState } from 'react';
import { Button, Spin } from 'antd';
import { AudioOutlined, AudioMutedOutlined } from '@ant-design/icons';
import { Connection, Device } from 'twilio-client';
import { useCallTimer } from 'common/hooks/useCallTimer';
import { ETimeFormat } from 'common/const/enum';
import { getFormattedTime } from 'common/helpers/time.helper';
import { ReactComponent as PhoneIcon } from 'app/assets/images/svg/phone.svg';

interface IComponentProps {
  token: string | null;
  phone?: string;
  onCall: boolean;
  setOnCall: (onCall: boolean) => void;
  setDuration: (duration: number) => void;
  onEndCallClick: () => void;
}

export const VoiceCallActive: FC<IComponentProps> = ({ token, phone, onCall, setOnCall, setDuration, onEndCallClick }) => {
  const [mute, setMute] = useState<boolean>(false);
  const [device, setDevice] = useState<Device | null>(null);
  const [connection, setConnection] = useState<Connection | null>(null);
  const { milliseconds: duration } = useCallTimer(onCall);

  const toggleMute = () => {
    setMute((prev) => {
      if (connection) {
        prev ? connection.mute(false) : connection.mute(true);
      }

      return !prev;
    });
  };

  const handleEndCallClick = () => {
    device?.disconnectAll();
    onEndCallClick();
  };

  useEffect(() => {
    const device = new Device();

    if (token) {
      device.setup(token);

      device.on('ready', () => {
        setDevice(device);
        const connection = device.connect({ number: phone as string });
        setConnection(connection);
      });

      device.on('connect', () => {
        setOnCall(true);
      });

      device.on('disconnect', () => {
        handleEndCallClick();
      });
    }

    return () => {
      device.destroy();
      setDevice(null);
      setConnection(null);
    };
  }, [token]);

  useEffect(() => {
    if (onCall) {
      setDuration(duration);
    }
  }, [onCall, duration]);

  return (
    <div className="call__voice-active">
      <div className="call__voice-active-time">
        {!onCall ? <Spin /> : <span>{getFormattedTime(duration, ETimeFormat.MinSec)}</span>}
      </div>

      <div className="call__voice-active-container">
        <Button className="btn default icon" icon={mute ? <AudioMutedOutlined /> : <AudioOutlined />} onClick={toggleMute} />

        <Button className="btn red call__voice-active-end-call" icon={<PhoneIcon />} onClick={handleEndCallClick}>
          End call
        </Button>
      </div>
    </div>
  );
};
