import React, { FC, useEffect, useState } from 'react';
import { Pagination, Table } from 'antd';
import { toDataSourceMapper } from 'common/helpers/data.helper';
import { useAppDispatch } from 'common/hooks/useAppDispatch';
import { useAppSelector } from 'common/hooks/useAppSelector';
import { TABLE_LIMIT } from 'common/config';
import { renderAssessmentHistoryRecords } from 'entities/PatientMetrics/PatientMetrics.helper';

interface IComponentProps {
  patientId: number;
}

export const AssessmentHistory: FC<IComponentProps> = ({ patientId }) => {
  const { assessmentHistory, count, loading } = useAppSelector((state) => ({
    assessmentHistory: state.patientMetrics.assessmentHistoryCollection,
    count: state.patientMetrics.assessmentHistoryCollectionCount,
    loading: state.patientMetrics.loading,
  }));
  const { patientMetrics: patientMetricsDispatch } = useAppDispatch();

  const [page, setPage] = useState(1);

  const { getAssessmentHistory } = patientMetricsDispatch;

  const dataSource = toDataSourceMapper(assessmentHistory);
  const columns = renderAssessmentHistoryRecords();

  useEffect(() => {
    getAssessmentHistory({ patientId, offset: TABLE_LIMIT * (page - 1), limit: TABLE_LIMIT });
  }, [patientId, page]);

  return (
    <div className="assessment-history">
      <div className="assessment-history__header">
        <div className="assessment-history__title">Assessment History</div>

        <a
          onClick={() => {
            window.open(
              `https://dev.celerihealth.com/home/resources/scheduleds/new?viaResource=patients&viaResourceId=${patientId}&viaRelationship=scheduled`,
              '_blank',
            );
          }}
        >
          Send Assessment to Patient
        </a>
      </div>

      <Table dataSource={dataSource} columns={columns} pagination={false} loading={loading} />

      <Pagination current={page} total={count} showSizeChanger={false} onChange={setPage} defaultPageSize={TABLE_LIMIT} />
    </div>
  );
};
