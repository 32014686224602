import React, { FC } from 'react';
import { IMessage, IMessageItemAssessment } from 'app/models/message.models';
import { ReactComponent as CheckIcon } from 'app/assets/images/svg/check.svg';

interface IComponentProps {
  item: IMessage;
}

// TODO Remove any
export const AssessmentType: FC<IComponentProps> = ({ item }) => {
  return (
    <div className="messages__assessment">
      {/* @ts-ignore */}
      {(item.data as IMessageItemAssessment).results.answers.map((answer: any, index: number) => {
        if (index === 0) {
          return null;
        }

        return (
          <div key={index}>
            <div className="messages__assessment-item-label">{answer.question.label}</div>

            <div>
              {answer.values.map((value: any) => {
                return (
                  <div key={value.id} className="messages__assessment-item-value">
                    <CheckIcon />

                    {value.value.checkbox ? <span>{answer.question.view.checkboxText}</span> : <span>{value.value.value}</span>}
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};
