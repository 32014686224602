import React, { FC, useEffect, useState } from 'react';
import { Button, Modal, Spin } from 'antd';
import { useAppSelector } from 'common/hooks/useAppSelector';
import { TimeEditorItem } from 'entities/Modal/components/TimeEditorItem';
import { timerValueToMilliseconds } from 'entities/Timer/Timer.helper';

interface IComponentProps {
  hours: number;
  minutes: number;
  seconds: number;
  onSave: (milliseconds: number) => void;
  onCancel: () => void;
}

export const TimeEditorModal: FC<IComponentProps> = (props) => {
  const { hours, minutes, seconds, onSave, onCancel } = props;
  const { open, loading } = useAppSelector((state) => ({
    open: state.modalCollection.timeEditorModalParams.open,
    loading: state.timer.loading,
  }));
  const [hoursValue, setHoursValue] = useState<number>(0);
  const [minutesValue, setMinutesValue] = useState<number>(0);
  const [secondsValue, setSecondsValue] = useState<number>(0);

  const handleSaveClick = () => {
    const milliseconds = timerValueToMilliseconds(hoursValue, minutesValue, secondsValue);
    onSave(milliseconds);
  };

  useEffect(() => {
    if (open) {
      setHoursValue(hours);
      setMinutesValue(minutes);
      setSecondsValue(seconds);
    }
  }, [open]);

  return (
    <Modal className="modal time-editor" title="Edit spent time" open={open} footer={false} onCancel={onCancel} width={600}>
      <Spin spinning={loading}>
        <div className="modal__body time-editor__body">
          <TimeEditorItem value={hoursValue} setValue={setHoursValue} isHours />

          <span className="time-editor__sep">:</span>

          <TimeEditorItem value={minutesValue} setValue={setMinutesValue} />

          <span className="time-editor__sep">:</span>

          <TimeEditorItem value={secondsValue} setValue={setSecondsValue} />
        </div>

        <div className="time-editor__footer">
          <Button className="btn blue" onClick={handleSaveClick}>
            Save
          </Button>

          <Button className="btn default" onClick={onCancel}>
            Cancel
          </Button>
        </div>
      </Spin>
    </Modal>
  );
};
