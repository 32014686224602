import {
  LocalAudioTrackPublication,
  LocalVideoTrackPublication,
  RemoteAudioTrackPublication,
  RemoteVideoTrackPublication,
} from 'twilio-video';

export const trackpubsToTracks = (
  trackMap: Map<
    string,
    RemoteVideoTrackPublication | RemoteAudioTrackPublication | LocalVideoTrackPublication | LocalAudioTrackPublication
  >,
) => {
  return Array.from(trackMap.values())
    .map((publication) => publication.track)
    .filter((track) => track !== null);
};
