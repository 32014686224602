import React, { FC, useEffect, useState } from 'react';
import { Button, Input, Modal, Spin } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';

interface IComponentProps {
  open: boolean;
  loading: boolean;
  onCompleteClick: (text: string) => void;
  onCancelClick: () => void;
}

export const CompleteModal: FC<IComponentProps> = ({ open, loading, onCompleteClick, onCancelClick }) => {
  const [text, setText] = useState<string>('');

  const handleCompleteClick = () => {
    onCompleteClick(text);
  };

  useEffect(() => {
    if (!open) {
      setText('');
    }
  }, [open]);

  return (
    <Modal className="modal" title="Complete" open={open} footer={false} onCancel={onCancelClick} width={600}>
      <Spin spinning={loading}>
        <div className="modal__body">
          <Input.TextArea placeholder="Message" rows={6} onChange={(e) => setText(e.target.value)} value={text} />
        </div>

        <div className="modal__footer">
          <Button
            className="btn green"
            onClick={handleCompleteClick}
            icon={<CheckCircleOutlined />}
            disabled={!text.trim().length}
          >
            Complete
          </Button>

          <Button className="btn default" onClick={onCancelClick}>
            Cancel
          </Button>
        </div>
      </Spin>
    </Modal>
  );
};
