import React, { FC } from 'react';
import dayjs from 'dayjs';
import { ETimeFormat } from 'common/const/enum';
import { getFormattedTime } from 'common/helpers/time.helper';
import { IMessage, IMessageItemCall } from 'app/models/message.models';
import { ReactComponent as OutcomingCall } from 'app/assets/images/svg/outcoming-call.svg';

interface IComponentProps {
  item: IMessage;
}

export const VoiceCallType: FC<IComponentProps> = ({ item }) => {
  const { createdAt, data } = item;
  const time = dayjs(createdAt).format(ETimeFormat.ShortTime12);
  const duration = (data as IMessageItemCall).duration;

  return (
    <div className="messages__call">
      <div className="messages__call-time">{time}</div>

      <div className="messages__call-container">
        <OutcomingCall />

        <div className="messages__call-data">{`Outgoing call ${
          duration ? 'for ' + getFormattedTime(duration, ETimeFormat.ConversationDuration) : ''
        }`}</div>
      </div>
    </div>
  );
};
