export enum ERoute {
  Login = '/login',
  Dashboard = '/dashboard',
  Operations = '/operations',
  Event = '/event/:id',
}

export enum EHeaderNavItemLabel {
  Dashboard = 'Dashboard',
  Operations = 'Operations',
}

export enum EEventStatus {
  Inbox = 'inbox',
  UnderReview = 'underReview',
  Escalated = 'escalated',
  Completed = 'completed',
  Сancelled = 'cancelled',
}

export enum EEventStatusLabel {
  inbox = 'Inbox',
  underReview = 'Under Review',
  escalated = 'Escalated',
  completed = 'Completed',
  cancelled = 'Сancelled',
}

export enum EEventDetailsItemLabel {
  gender = 'Gender',
  age = 'Age',
  dob = 'DOB',
  language = 'Language',
  bestAvailability = 'Best Availability',
  phone = 'Mobile phone',
}

export enum EEventMenuKey {
  Messages = 'messages',
  PatientDetails = 'patientDetails',
}

export enum EEventHistoryMenuKey {
  Notes = 'notes',
  EventHistory = 'event_history',
  All = 'all',
}

export enum EMetricType {
  Sleep = 'sleep',
  Steps = 'steps',
  Heart = 'heart',
}

export enum EModalKey {
  Inactivity = 'inactivityModalParams',
  TimeEditor = 'timeEditorModalParams',
  Complete = 'completeModalParams',
  Escalate = 'escalateModalParams',
}

export enum EUserRole {
  Staff = 'staff',
  Physician = 'physician',
}

export enum EDateFormat {
  DayShortMonthTime12 = 'D MMM, hh:mma',
  FullDateShortSlash = 'MM/DD/YY',
  FullDateTime12Slash = 'MM/DD/YY - hh:mma',
  DayFullMonth = 'DD MMMM',
}

export enum ETimeFormat {
  TimeWithUnit = 'ddd hhh mmm sss',
  FullTime = 'HH:mm:ss',
  ShortTime12 = 'hh:mm a',
  MinSec = 'mm:ss',
  ConversationDuration = 'hhh mmm sss',
}

export enum EEventType {
  Message = 'message',
  Call = 'call',
  VideoCall = 'videoCall',
  Lucy = 'lucy',
}

export enum EEventTypeLabel {
  message = 'New Message',
  call = 'Outgoing call',
  videoCall = 'Incoming call',
  lucy = 'L.U.C.Y',
}

export enum EErrorStatus {
  BadRequest = 400,
  Unauthorized = 401,
  Forbidden = 403,
  NotFound = 404,
  UnprocessableEntity = 422,
  InternalServerError = 500,
}

export enum EOrderDirection {
  Ascend = 'ascend',
  Descend = 'descend',
}

export enum EOrderDirectionShort {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum EEventActionType {
  MoveToInbox = 'moveToInbox',
  Complete = 'complete',
  Escalate = 'escalate',
  Unescalate = 'unescalate',
  Back = 'back',
}

export enum EHistoryType {
  received = 'received',
  moved_back = 'moved_back',
  completed = 'completed',
  call = 'call',
  spentTimeRecordAdded = 'spentTimeRecordAdded',
  spentTimeRecordEdited = 'spentTimeRecordEdited',
  commentIsLeft = 'commentIsLeft',
  spentTimeCancelled = 'spentTimeCancelled',
  review = 'review',
  release = 'release',
  escalated = 'escalated',
}

export enum EHistoryTypeLabel {
  received = 'Incoming event item for review',
  moved_back = 'Moved back to Inbox',
  completed = 'Event is completed',
  call = 'Call to the patient',
  spentTimeRecordAdded = 'Time spent',
  spentTimeRecordEdited = 'Time edited',
  commentIsLeft = 'A comment is left',
  spentTimeCancelled = 'Time deleted',
  review = 'Review',
  release = 'Released from hold',
  escalated = 'Event is escalated',
}

export enum ESocketEvent {
  Events = 'events',
  Sse = 'sse',
}

export enum EMessageType {
  Message = 'message',
  Call = 'call',
  VideoCall = 'videoCall',
  Lucy = 'lucy',
  Assessment = 'assessment',
  TechnicalAssessment = 'technicalAssessment',
  StartConversation = 'startConversation',
  EndConversation = 'endConversation',
}

export enum EQuestionType {
  'multipleChoice' = 'multipleChoice',
  'textOrSkip' = 'textOrSkip',
}
