import axios from 'axios';
import { IAssessmentHistoryCollectionDto, IAssessmentHistoryCollectionParams } from 'app/models/patientMetrics.models';

const basePath = '/patient-metrics';

export const patientMetricsService = {
  getAssessmentHistory: ({
    patientId,
    offset,
    limit,
  }: IAssessmentHistoryCollectionParams): Promise<IAssessmentHistoryCollectionDto> =>
    axios.get(`${basePath}/${patientId}/assessment-history`, { params: { offset, limit } }),
};
