import React, { FC, useEffect, useRef } from 'react';
import { Button, message } from 'antd';
import { Room, connect } from 'twilio-video';
import { useAppDispatch } from 'common/hooks/useAppDispatch';
import { useAppSelector } from 'common/hooks/useAppSelector';
import { ReactComponent as PhoneIcon } from 'app/assets/images/svg/phone.svg';
import { VideoRoom } from 'entities/Calls/components/VideoCall/VideoRoom';

interface IComponentProps {
  eventId: number;
}

export const VideoCallWidget: FC<IComponentProps> = ({ eventId }) => {
  const { patient, twilio } = useAppSelector((state) => ({ patient: state.patient, twilio: state.twilio }));
  const { twilio: twilioDispatch } = useAppDispatch();

  const roomRef = useRef<Room | null>(null);

  const { viewVideoCallWidget, videoToken } = twilio;
  const { setTwilioViewVideoCallWidget, getVideoCallToken, setTwilioOnCall, resetTwilioData } = twilioDispatch;

  const patientName = patient.data?.name;

  const rejectIncomingCall = () => {
    setTwilioViewVideoCallWidget(false);
  };

  const acceptIncomingCall = () => {
    getVideoCallToken({ eventId });
  };

  const onEndCallClick = () => {
    roomRef.current?.disconnect();
    roomRef.current = null;
    resetTwilioData();
  };

  useEffect(() => {
    if (videoToken) {
      const connectToRoom = async () => {
        try {
          const room = await connect(videoToken, {
            audio: true,
            video: true,
          });

          roomRef.current = room;
          setTwilioViewVideoCallWidget(false);
          setTwilioOnCall(true);
        } catch (error) {
          // @ts-ignore
          message.error(error.message);
        }
      };

      connectToRoom();
    }
  }, [videoToken]);

  useEffect(() => {
    return () => {
      roomRef.current?.disconnect();
      roomRef.current = null;
      resetTwilioData();
    };
  }, []);

  if (viewVideoCallWidget && !roomRef.current) {
    return (
      <div className="video-call-widget">
        <div className="video-call-widget__user">
          <div className="video-call-widget__user-icon">{patientName?.match(/\b(\w)/g)?.join('')}</div>

          <div className="video-call-widget__user-name">{patientName}</div>
        </div>

        <div className="video-call-widget__container">
          <Button className="btn red icon video-call-widget__reject-btn" icon={<PhoneIcon />} onClick={rejectIncomingCall} />

          <Button className="btn green icon" icon={<PhoneIcon />} onClick={acceptIncomingCall} />
        </div>

        <audio src="/incomingvideosound.mp3" autoPlay={true} muted={false} />
      </div>
    );
  }

  if (roomRef.current) {
    return <VideoRoom room={roomRef.current} onEndCallClick={onEndCallClick} />;
  }

  return null;
};
