import { EEventStatus, EEventStatusLabel } from 'common/const/enum';

export const eventsListRadioBtns = {
  forStaff: [
    {
      label: EEventStatusLabel.inbox,
      value: EEventStatus.Inbox,
    },
    {
      label: EEventStatusLabel.underReview,
      value: EEventStatus.UnderReview,
    },
    {
      label: EEventStatusLabel.escalated,
      value: EEventStatus.Escalated,
    },
    {
      label: EEventStatusLabel.completed,
      value: EEventStatus.Completed,
    },
  ],
  forPhysician: [
    {
      label: EEventStatusLabel.inbox,
      value: EEventStatus.Inbox,
    },
    {
      label: EEventStatusLabel.underReview,
      value: EEventStatus.UnderReview,
    },
    {
      label: EEventStatusLabel.completed,
      value: EEventStatus.Completed,
    },
  ],
};
