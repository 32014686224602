import React, { FC } from 'react';
import { Button } from 'antd';
import { EEventActionType, EModalKey, EUserRole } from 'common/const/enum';
import { useAppSelector } from 'common/hooks/useAppSelector';
import { useAppDispatch } from 'common/hooks/useAppDispatch';
import { history } from 'app/store';
import { Timer } from 'entities/Timer/components/Timer';
import { getEventAvailableOperations } from 'entities/Events/Events.helper';
import { CompleteModal } from 'entities/Modal/components/CompleteModal';
import { EscalateModal } from 'entities/Modal/components/EscalateModal';

interface IComponentProps {
  eventId: number;
}

export const EventControls: FC<IComponentProps> = ({ eventId }) => {
  const { auth, event, timer, completeModalParams, escalateModalParams, physicianCollecton } = useAppSelector((state) => ({
    auth: state.auth,
    event: state.event,
    timer: state.timer,
    physicianCollecton: state.physicianCollecton,
    completeModalParams: state.modalCollection.completeModalParams,
    escalateModalParams: state.modalCollection.escalateModalParams,
  }));
  const { event: eventDispatch, timer: timerDispatch, modalCollection: modalCollectionDispatch } = useAppDispatch();

  const { data: authData } = auth;
  const { data: eventData, loading: eventLoading, isEscalated } = event;
  const { timerIsRunning } = timer;
  const { data: physicianCollectonData } = physicianCollecton;
  const { open: openCompleteModal } = completeModalParams;
  const { open: openEscalateModal } = escalateModalParams;
  const { setEventAction, setEventText, setEventPhysicianId, escalateEvent } = eventDispatch;
  const { stopTimer } = timerDispatch;
  const { showModal, hideModal } = modalCollectionDispatch;

  const currentUserId = authData?.access?.id;
  const role = authData?.access?.rights[0];

  const { canStart, autoStart, canBeEscalated, canBeUnescalated, canBeCompleted } = getEventAvailableOperations({
    status: eventData?.status,
    role,
    currentUserId,
    staffId: eventData?.staffId,
    physicianId: eventData?.physicianId,
  });

  const onCompleteClick = () => {
    showModal(EModalKey.Complete);
  };

  const onCompleteModalCancelClick = () => {
    hideModal(EModalKey.Complete);
  };

  const onCompleteModalCompleteClick = (text: string) => {
    setEventAction(EEventActionType.Complete);
    setEventText(text);

    if (timerIsRunning) {
      stopTimer({
        eventId,
        onSuccess: () => history.back(),
      });
    } else {
      history.back();
    }
  };

  const onEscalateClick = () => {
    showModal(EModalKey.Escalate);
  };

  const onEscalateModalCancelClick = () => {
    hideModal(EModalKey.Escalate);
  };

  const onEscalateModalSendClick = (text: string, physicianId: number) => {
    setEventAction(EEventActionType.Escalate);
    setEventText(text);
    setEventPhysicianId(physicianId);

    if (timerIsRunning) {
      stopTimer({
        eventId,
        onSuccess: () => history.back(),
      });
    } else {
      history.back();
    }
  };

  const onUnescalateClick = () => {
    if (role === EUserRole.Staff) {
      escalateEvent({ id: eventId, escalated: false });
    } else {
      setEventAction(EEventActionType.Unescalate);

      if (timerIsRunning) {
        stopTimer({
          eventId,
          onSuccess: () => history.back(),
        });
      } else {
        history.back();
      }
    }
  };

  return (
    <div className="event-controls">
      <Timer eventId={eventId} canStart={canStart} autoStart={autoStart} />

      <div className="divider" />

      <div className="event-controls__actions">
        <Button className="btn green" onClick={onCompleteClick} disabled={!canBeCompleted}>
          Complete
        </Button>

        {role === EUserRole.Staff && !isEscalated && (
          <Button className="btn red" onClick={onEscalateClick} disabled={!canBeEscalated}>
            Escalate
          </Button>
        )}

        {(role === EUserRole.Physician || (role === EUserRole.Staff && isEscalated)) && (
          <Button className="btn red" onClick={onUnescalateClick} disabled={!canBeUnescalated}>
            Unescalate
          </Button>
        )}
      </div>

      <CompleteModal
        open={openCompleteModal}
        loading={eventLoading}
        onCompleteClick={onCompleteModalCompleteClick}
        onCancelClick={onCompleteModalCancelClick}
      />

      <EscalateModal
        open={openEscalateModal}
        loading={eventLoading}
        physicians={physicianCollectonData}
        onSendClick={onEscalateModalSendClick}
        onCancelClick={onEscalateModalCancelClick}
      />
    </div>
  );
};
