import { createModel } from '@rematch/core';
import { axiosErrorHandler } from 'common/helpers/axios.helper';
import { EEventActionType } from 'common/const/enum';
import {
  IEvent,
  IEventCollectionDto,
  IEventCollectionParams,
  IEventCollectionState,
  IEventPayload,
  IEventState,
} from 'app/models/event.models';
import { IRootModel } from 'app/store/slices';
import { eventService } from 'app/store/services/event.service';

export const eventCollection = createModel<IRootModel>()({
  state: {
    data: null,
    loading: true,
    watchUpdates: true,
  } as IEventCollectionState,
  reducers: {
    setEventCollection: (state, payload: IEventCollectionDto) => ({ ...state, data: payload }),
    setEventCollectionLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
    setEventCollectionWatchUpdates: (state, payload: boolean) => ({ ...state, watchUpdates: payload }),
  },
  effects: (dispatch) => ({
    async getEventCollection(params: IEventCollectionParams) {
      dispatch.eventCollection.setEventCollectionLoading(true);

      await eventService
        .getEventCollection(params)
        .then((response) => {
          dispatch.eventCollection.setEventCollection(response);
        })
        .catch(axiosErrorHandler)
        .finally(() => {
          dispatch.eventCollection.setEventCollectionLoading(false);
        });
    },
  }),
});

export const event = createModel<IRootModel>()({
  state: {
    data: null,
    loading: false,
    action: null,
    text: null,
    physicianId: null,
    isEscalated: false,
  } as IEventState,
  reducers: {
    setEvent: (state, payload: IEvent | null) => ({ ...state, data: payload }),
    setEventLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
    setEventAction: (state, payload: EEventActionType | null) => ({ ...state, action: payload }),
    setEventText: (state, payload: string | null) => ({ ...state, text: payload }),
    setEventPhysicianId: (state, payload: number | null) => ({ ...state, physicianId: payload }),
    setEventIsEscalated: (state, payload: boolean) => ({ ...state, isEscalated: payload }),
  },
  effects: (dispatch) => ({
    async getEventById(id: number) {
      dispatch.event.setEventLoading(true);

      return await eventService
        .getEventById(id)
        .then((response) => {
          dispatch.event.setEvent(response);
          dispatch.event.setEventIsEscalated(response.isEscalated);
          return response;
        })
        .catch((error) => {
          axiosErrorHandler(error);
          return null;
        })
        .finally(() => {
          dispatch.event.setEventLoading(false);
        });
    },
    async reviewEvent(payload: IEventPayload) {
      dispatch.event.setEventLoading(true);
      dispatch.eventCollection.setEventCollectionWatchUpdates(false);
      dispatch.statistics.setStatisticsWatchUpdates(false);

      await eventService
        .reviewEvent(payload.id)
        .then((response) => {
          dispatch.event.setEvent(response);
          dispatch.global.onSuccess(payload);
        })
        .catch(axiosErrorHandler)
        .finally(() => {
          dispatch.event.setEventLoading(false);
        });
    },
    async moveEventToInbox(payload: IEventPayload) {
      dispatch.event.setEventLoading(true);

      await eventService
        .moveEventToInbox(payload.id)
        .then(() => {
          dispatch.global.onSuccess(payload);
        })
        .catch(axiosErrorHandler)
        .finally(() => {
          dispatch.event.setEventLoading(false);
        });
    },
    async completeEvent(payload: IEventPayload) {
      dispatch.event.setEventLoading(true);

      await eventService
        .completeEvent(payload)
        .then(() => {
          dispatch.global.onSuccess(payload);
        })
        .catch(axiosErrorHandler)
        .finally(() => {
          dispatch.event.setEventLoading(false);
        });
    },
    async escalateEvent(payload: IEventPayload) {
      dispatch.event.setEventLoading(true);

      await eventService
        .escalateEvent(payload)
        .then((response) => {
          if (!response.isEscalated) {
            dispatch.event.setEventIsEscalated(false);
          }

          dispatch.global.onSuccess(payload);
        })
        .catch(axiosErrorHandler)
        .finally(() => {
          dispatch.event.setEventLoading(false);
        });
    },
    clearEvent() {
      dispatch.event.setEvent(null);
      dispatch.event.setEventAction(null);
      dispatch.event.setEventText(null);
      dispatch.event.setEventPhysicianId(null);
      dispatch.patient.setPatient(null);
      dispatch.eventCollection.setEventCollectionWatchUpdates(true);
      dispatch.statistics.setStatisticsWatchUpdates(true);
      dispatch.timer.clearTimer();
      dispatch.patientMetrics.setAssessmentHistoryCollection([]);
      dispatch.twilio.setTwilioViewVideoCallWidget(false);
    },
  }),
});
