import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { formats, modules } from 'entities/Notes/Notes.conts';

interface IComponentProps {
  value: string;
  onChange: (content: string) => void;
}

export const TextEditor: React.FC<IComponentProps> = ({ value, onChange }) => {
  return (
    <div className="text-editor">
      <ReactQuill modules={modules} formats={formats} value={value} onChange={onChange} />
    </div>
  );
};
