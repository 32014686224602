import { Models } from '@rematch/core';
import { modalCollection } from 'app/store/slices/modal.slice';
import { global } from 'app/store/slices/global.slice';
import { auth } from 'app/store/slices/auth.slice';
import { eventCollection, event } from 'app/store/slices/event.slice';
import { patient } from 'app/store/slices/patient.slice';
import { timer } from 'app/store/slices/timer.slice';
import { note, noteCollection } from 'app/store/slices/note.slice';
import { history } from 'app/store/slices/history.slice';
import { message, messageCollection } from 'app/store/slices/message.slice';
import { statistics } from 'app/store/slices/statistics.slice';
import { physicianCollecton } from 'app/store/slices/physicians.slice';
import { twilio } from 'app/store/slices/twilio.slice';
import { assessmentCollection } from 'app/store/slices/assessment.slice';
import { patientMetrics } from 'app/store/slices/patientMetrics.slice';

export interface IRootModel extends Models<IRootModel> {
  modalCollection: typeof modalCollection;
  global: typeof global;
  auth: typeof auth;
  eventCollection: typeof eventCollection;
  event: typeof event;
  patient: typeof patient;
  timer: typeof timer;
  noteCollection: typeof noteCollection;
  note: typeof note;
  history: typeof history;
  messageCollection: typeof messageCollection;
  message: typeof message;
  statistics: typeof statistics;
  physicianCollecton: typeof physicianCollecton;
  twilio: typeof twilio;
  assessmentCollection: typeof assessmentCollection;
  patientMetrics: typeof patientMetrics;
}

export const models = {
  modalCollection,
  global,
  auth,
  eventCollection,
  event,
  patient,
  timer,
  noteCollection,
  note,
  history,
  messageCollection,
  message,
  statistics,
  physicianCollecton,
  twilio,
  assessmentCollection,
  patientMetrics,
} as IRootModel;
