import React, { FC, useState } from 'react';
import { Menu, Spin } from 'antd';
import type { MenuProps } from 'antd';
import { useAppSelector } from 'common/hooks/useAppSelector';
import { EEventHistoryMenuKey } from 'common/const/enum';
import { eventHistoryMenuItems } from 'entities/History/History.const';
import { HistoryNote } from 'entities/History/components/HistoryNote';
import { HistoryItem } from 'entities/History/components/HistoryItem';
import { getFullHistory } from 'entities/History/History.helper';

export const History: FC = () => {
  const { notes, history } = useAppSelector((state) => ({ notes: state.noteCollection, history: state.history }));

  const [menuKey, setMenuKey] = useState<EEventHistoryMenuKey>(EEventHistoryMenuKey.Notes);

  const { data: notesData, loading: notesLoading } = notes;
  const { data: historyData, loading: historyLoading } = history;

  const onMenuKeyClick: MenuProps['onClick'] = (e) => {
    setMenuKey(e.key as EEventHistoryMenuKey);
  };

  const renderContent = () => {
    switch (menuKey) {
      case EEventHistoryMenuKey.EventHistory: {
        return historyData?.data.map((item) => <HistoryItem key={item.id} item={item} />);
      }
      case EEventHistoryMenuKey.All: {
        const fullHistory = getFullHistory(historyData?.data, notesData?.data);

        return fullHistory?.map((item) => {
          // @ts-ignore
          return item.note ? <HistoryNote key={item.id} note={item} /> : <HistoryItem key={item.id} item={item} />;
        });
      }
      default: {
        return notesData?.data.map((note) => <HistoryNote key={note.id} note={note} />);
      }
    }
  };

  return (
    <div className="history">
      <div className="history__title">Review history</div>

      <Menu mode="horizontal" items={eventHistoryMenuItems} selectedKeys={[menuKey]} onClick={onMenuKeyClick} />

      <Spin spinning={notesLoading || historyLoading}>
        <div className="history__content">{renderContent()}</div>
      </Spin>
    </div>
  );
};
