import axios from 'axios';
import { IEvent, IEventCollectionDto, IEventCollectionParams, IEventPayload } from 'app/models/event.models';

const basePath = '/events';
const actionPath = '/action';

export const eventService = {
  getEventCollection: (params: IEventCollectionParams): Promise<IEventCollectionDto> => axios.get(`${basePath}`, { params }),
  getEventById: (id: number): Promise<IEvent> => axios.get(`${basePath}/review/${id}`),
  reviewEvent: (id: number): Promise<IEvent> => axios.patch(`${actionPath}/review/${id}`),
  moveEventToInbox: (id: number): Promise<IEvent> => axios.patch(`${actionPath}/return/${id}`),
  completeEvent: ({ id, text }: IEventPayload): Promise<IEvent> => axios.patch(`${actionPath}/complete/${id}`, { text }),
  escalateEvent: ({ id, text, escalated, physicianId }: IEventPayload): Promise<IEvent> =>
    axios.patch(`${actionPath}/escalate/${id}`, { text, escalated, physicianId }),
};
