import React, { FC } from 'react';
import { Button, Form, Input, Spin } from 'antd';
import { Navigate } from 'react-router-dom';
import { rules } from 'common/helpers/form.helper';
import { useAppDispatch } from 'common/hooks/useAppDispatch';
import { useAppSelector } from 'common/hooks/useAppSelector';
import { ERoute } from 'common/const/enum';

export const Login: FC = () => {
  const { auth } = useAppSelector((state) => ({ auth: state.auth }));
  const { auth: authDispatch } = useAppDispatch();

  const { data, loading } = auth;
  const { login } = authDispatch;

  if (data) {
    return <Navigate to={ERoute.Dashboard} />;
  }

  return (
    <div className="login">
      <Form className="login__form box-shadow" onFinish={login}>
        <Spin spinning={loading}>
          <div className="login__title">Login</div>

          <Form.Item name="email" rules={[rules.required()]}>
            <Input placeholder="E-mail" />
          </Form.Item>

          <Form.Item name="password" rules={[rules.required()]}>
            <Input.Password placeholder="Password" />
          </Form.Item>

          <Form.Item>
            <Button className="btn blue" htmlType="submit">
              Login
            </Button>
          </Form.Item>
        </Spin>
      </Form>
    </div>
  );
};
