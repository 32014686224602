import React, { FC, useMemo } from 'react';
import { assessmentQuestionAnswerToKeyCodes } from 'entities/Assessment/Assessment.helper';

// TODO remove any
interface IComponentProps {
  assessment: any;
  answer: any;
  index: number;
}

export const EventTooltipItem: FC<IComponentProps> = ({ assessment, answer, index }) => {
  const renderKeyCodes = useMemo(() => {
    return assessmentQuestionAnswerToKeyCodes(answer, index);
  }, [answer, index, assessment]);

  return <div>{renderKeyCodes}</div>;
};
