import React, { FC, useState } from 'react';
import { Input, Pagination, Select, Table } from 'antd';
import type { TableProps } from 'antd/es/table';
import { SorterResult } from 'antd/es/table/interface';
import { useNavigate } from 'react-router-dom';
import { FilterRadio } from 'common/components/FilterRadio';
import { toDataSourceMapper } from 'common/helpers/data.helper';
import { EEventStatus, EOrderDirection, ESocketEvent, EUserRole } from 'common/const/enum';
import { useAppDispatch } from 'common/hooks/useAppDispatch';
import { useDebounce } from 'common/hooks/useDebounce';
import { useAppSelector } from 'common/hooks/useAppSelector';
import { TABLE_LIMIT } from 'common/config';
import { useSocket } from 'common/hooks/useSocket';
import { useNotification } from 'common/hooks/useNotification';
import { ReactComponent as ArrowDownIcon } from 'app/assets/images/svg/arrow-down.svg';
import { IEvent, IEventListItem } from 'app/models/event.models';
import { eventsListRadioBtns } from 'entities/Events/Events.const';
import { getEventAvailableOperations, getEventCollectionPayload, renderEventListRecords } from 'entities/Events/Events.helper';

const { Search } = Input;

export const EventList: FC = () => {
  const { auth, eventCollection, event } = useAppSelector((state) => ({
    auth: state.auth,
    eventCollection: state.eventCollection,
    event: state.event,
  }));
  const { eventCollection: eventCollectionDispatch, event: eventDispatch } = useAppDispatch();

  const [page, setPage] = useState<number>(1);
  const [orderDirection, setOrderDirection] = useState<EOrderDirection | undefined>(undefined);
  const [status, setStatus] = useState<EEventStatus>(EEventStatus.Inbox);
  const [orderField, setOrderField] = useState<string>('createdAt');
  const navigate = useNavigate();
  const { newEventNotificationContext, showNewEventNotification } = useNotification();

  const { data: authData } = auth;
  const { data: eventCollectionData, loading: eventCollectionLoading, watchUpdates } = eventCollection;
  const { loading: eventLoading } = event;
  const { getEventCollection } = eventCollectionDispatch;
  const { getEventById, reviewEvent } = eventDispatch;

  const currentUserId = authData?.access?.id;
  const role = authData?.access?.rights[0];
  const dataSource = toDataSourceMapper(eventCollectionData?.data);
  const columns = renderEventListRecords(status);
  const payload = getEventCollectionPayload(page, status, orderField, orderDirection, role);

  const onTableChange: TableProps<IEventListItem>['onChange'] = (
    p,
    f,
    sorter: SorterResult<IEventListItem> | SorterResult<IEventListItem>[],
  ) => {
    setOrderDirection((sorter as SorterResult<IEventListItem>).order as EOrderDirection | undefined);
    setOrderField((sorter as SorterResult<IEventListItem>).field as string);
  };

  const onEventListItemClick = (id: number) => {
    getEventById(id).then((response) => {
      if (response) {
        const { canReview } = getEventAvailableOperations({
          status: response.status,
          role,
          currentUserId,
          staffId: response.staffId,
          physicianId: response.physicianId,
        });

        if (canReview) {
          reviewEvent({
            id,
            onSuccess: () => {
              navigate(`/event/${id}`);
            },
          });
        } else {
          navigate(`/event/${id}`);
        }
      }
    });
  };

  useSocket(
    ESocketEvent.Events,
    (event: IEvent) => {
      if (watchUpdates) {
        // @ts-ignore
        getEventCollection(payload);

        if (event.status === EEventStatus.Inbox) {
          showNewEventNotification();
        }
      }
    },
    [watchUpdates],
  );

  useDebounce(() => {
    // @ts-ignore
    getEventCollection(payload);
  }, [page, orderDirection, status, orderField]);

  return (
    <div className="events-list">
      <div className="events-list__filter">
        <Search className="events-list__filter-search" placeholder="Search by patient / assignee / description" />

        <div className="events-list__filter-event-type">
          <span className="events-list__filter-event-type-title">Event Type</span>

          <Select className="events-list__filter-event-type-select" suffixIcon={<ArrowDownIcon />} />
        </div>
      </div>

      <FilterRadio
        btns={role === EUserRole.Staff ? eventsListRadioBtns.forStaff : eventsListRadioBtns.forPhysician}
        value={status}
        onChange={(value) => setStatus(value as EEventStatus)}
      />

      <Table
        className="events-list__table"
        dataSource={dataSource}
        columns={columns}
        onChange={onTableChange}
        pagination={false}
        onRow={(record) => ({ onClick: () => onEventListItemClick(record.id) })}
        loading={eventCollectionLoading || eventLoading}
      />

      <Pagination
        current={page}
        total={eventCollectionData?.meta.count}
        showSizeChanger={false}
        onChange={setPage}
        defaultPageSize={TABLE_LIMIT}
      />

      {newEventNotificationContext}
    </div>
  );
};
