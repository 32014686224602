import { createModel } from '@rematch/core';
import { axiosErrorHandler } from 'common/helpers/axios.helper';
import { IRootModel } from 'app/store/slices';
import { IStatistics, IStatisticsState } from 'app/models/statistics.models';
import { statisticsService } from 'app/store/services/statistics.service';

export const statistics = createModel<IRootModel>()({
  state: {
    data: null,
    loading: false,
    watchUpdates: true,
  } as IStatisticsState,
  reducers: {
    setStatistics: (state, payload: IStatistics) => ({ ...state, data: payload }),
    setStatisticsLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
    setStatisticsWatchUpdates: (state, payload: boolean) => ({ ...state, watchUpdates: payload }),
  },
  effects: (dispatch) => ({
    async getStatistics(role: string) {
      dispatch.statistics.setStatisticsLoading(true);

      await statisticsService
        .getStatistics(role)
        .then((response) => {
          dispatch.statistics.setStatistics(response);
        })
        .catch(axiosErrorHandler)
        .finally(() => {
          dispatch.statistics.setStatisticsLoading(false);
        });
    },
  }),
});
