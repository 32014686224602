import { init, RematchRootState, RematchDispatch } from '@rematch/core';
import { createBrowserHistory } from 'history';
import { createReduxHistoryContext } from 'redux-first-history';
import { IRootModel, models } from 'app/store/slices';

const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
  history: createBrowserHistory(),
});

const reducers = { router: routerReducer };

const store = init<IRootModel>({
  redux: {
    reducers,
    middlewares: [routerMiddleware],
  },
  models,
});

export default store;
export const history = createReduxHistory(store);
export type AppState = RematchRootState<IRootModel>;
export type AppDispatch = RematchDispatch<IRootModel>;
