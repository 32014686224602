import React, { FC } from 'react';
import { AssessmentHistory } from 'entities/PatientMetrics/components/AssessmentHistory';

interface IComponentProps {
  patientId: number;
}

export const PatientMetrics: FC<IComponentProps> = ({ patientId }) => {
  return (
    <div className="patient-metrics">
      <AssessmentHistory patientId={patientId} />
    </div>
  );
};
