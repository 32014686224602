import React, { FC, useEffect, useState } from 'react';
import { Button, Input, Modal, Select, Spin } from 'antd';
import { IPhysician } from 'app/models/physicians.models';
import { ReactComponent as ArrowDownIcon } from 'app/assets/images/svg/arrow-down.svg';

interface IComponentProps {
  open: boolean;
  loading: boolean;
  physicians: IPhysician[] | null;
  onSendClick: (text: string, physicianId: number) => void;
  onCancelClick: () => void;
}

export const EscalateModal: FC<IComponentProps> = ({ open, loading, physicians, onSendClick, onCancelClick }) => {
  const [text, setText] = useState<string>('');
  const [physicianId, setPhysicianId] = useState<number | null>(null);

  const physiciansOptions = physicians?.map((physician) => ({ label: physician.name, value: physician.id }));

  const handleSendClick = () => {
    if (physicianId) {
      onSendClick(text, physicianId);
    }
  };

  const onPhysicianSelect = (value: number) => {
    setPhysicianId(value);
  };

  useEffect(() => {
    if (!open) {
      setText('');
      setPhysicianId(null);
    }
  }, [open]);

  return (
    <Modal className="modal" title="Escalate to a physician" open={open} footer={false} onCancel={onCancelClick} width={600}>
      <Spin spinning={loading}>
        <div className="modal__body escalate-modal__body">
          <Select options={physiciansOptions} suffixIcon={<ArrowDownIcon />} value={physicianId} onChange={onPhysicianSelect} />

          <Input.TextArea placeholder="Message" rows={6} value={text} onChange={(e) => setText(e.target.value)} />
        </div>

        <div className="modal__footer">
          <Button className="btn blue" onClick={handleSendClick} disabled={!text.length || !physicianId}>
            Send
          </Button>

          <Button className="btn default" onClick={onCancelClick}>
            Cancel
          </Button>
        </div>
      </Spin>
    </Modal>
  );
};
