import { createModel } from '@rematch/core';
import { axiosErrorHandler } from 'common/helpers/axios.helper';
import { IRootModel } from 'app/store/slices';
import { IAfterCallPayload, ICallVideoTokenPayload, ITwilioState } from 'app/models/twilio.models';
import { twilioService } from 'app/store/services/twilio.service';

export const twilio = createModel<IRootModel>()({
  state: {
    voiceToken: null,
    videoToken: null,
    loading: false,
    onCall: false,
    duration: null,
    viewVideoCallWidget: false,
  } as ITwilioState,
  reducers: {
    setTwilioVoiceToken: (state, payload: string | null) => ({ ...state, voiceToken: payload }),
    setTwilioVideoToken: (state, payload: string | null) => ({ ...state, videoToken: payload }),
    setTwilioLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
    setTwilioOnCall: (state, payload: boolean) => ({ ...state, onCall: payload }),
    setTwilioDuration: (state, payload: number | null) => ({ ...state, duration: payload }),
    setTwilioViewVideoCallWidget: (state, payload: boolean) => ({ ...state, viewVideoCallWidget: payload }),
  },
  effects: (dispatch) => ({
    async getVoiceCallToken() {
      dispatch.twilio.setTwilioLoading(true);

      await twilioService
        .getVoiceCallToken()
        .then((response) => {
          dispatch.twilio.setTwilioVoiceToken(response.token);
        })
        .catch(axiosErrorHandler)
        .finally(() => {
          dispatch.twilio.setTwilioLoading(false);
        });
    },
    async trackVoiceCallDuration(payload: IAfterCallPayload) {
      dispatch.twilio.setTwilioLoading(true);

      await twilioService
        .trackVoiceCallDuration(payload)
        .then(() => {
          dispatch.twilio.setTwilioDuration(null);
        })
        .catch(axiosErrorHandler)
        .finally(() => {
          dispatch.twilio.setTwilioLoading(false);
        });
    },
    async getVideoCallToken(payload: ICallVideoTokenPayload) {
      dispatch.twilio.setTwilioLoading(true);

      await twilioService
        .getVideoCallToken(payload)
        .then((response) => {
          dispatch.twilio.setTwilioVideoToken(response.token);
        })
        .catch(axiosErrorHandler)
        .finally(() => {
          dispatch.twilio.setTwilioLoading(false);
        });
    },
    async getParticipantByIdentity(identity: string) {
      return await twilioService
        .getParticipantByIdentity(identity)
        .then((response) => {
          return { ...response, videoMuted: false, audioMuted: false };
        })
        .catch(axiosErrorHandler);
    },
    resetTwilioData() {
      dispatch.twilio.setTwilioVoiceToken(null);
      dispatch.twilio.setTwilioVideoToken(null);
      dispatch.twilio.setTwilioOnCall(false);
      dispatch.twilio.setTwilioDuration(null);
    },
  }),
});
