/* eslint-disable */
import React, { FC, useEffect } from 'react';
import { useAppDispatch } from 'common/hooks/useAppDispatch';
import { EventControls } from 'entities/Events/components/EventControls';
import { History } from 'entities/History/components/History';
import { VoiceCallWrapper } from 'entities/Calls/components/VoiceCall/VoiceCallWrapper';
import { Notes } from 'entities/Notes/components/Notes';
import { VideoCallWidget } from 'entities/Calls/components/VideoCall/VideoCallWidget';

interface IComponentProps {
  eventId: number;
  patientId: number;
}

export const EventSidebar: FC<IComponentProps> = ({ eventId, patientId }) => {
  const { noteCollection: noteCollectionDispatch, history: historyDispatch } = useAppDispatch();

  const { getNotes } = noteCollectionDispatch;
  const { getHistory } = historyDispatch;

  useEffect(() => {
    getNotes({ patientId });
    getHistory(eventId);
  }, [patientId]);

  return (
    <div className="event-sidebar">
      <EventControls eventId={eventId} />

      <VoiceCallWrapper eventId={eventId} />

      <VideoCallWidget eventId={eventId} />

      <Notes eventId={eventId} />

      <History />
    </div>
  );
};
