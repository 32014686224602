import { RefObject, useEffect } from 'react';

export const useScroll = (ref: RefObject<HTMLDivElement>, depth: any[]) => {
  useEffect(() => {
    const scrollToBottom = () => {
      if (ref.current) {
        const chatContainer = ref.current;

        chatContainer.scrollTop = chatContainer.scrollHeight;
      }
    };

    scrollToBottom();
  }, [...depth]);
};
