import React from 'react';
import { EMessageType, EOrderDirectionShort } from 'common/const/enum';
import { LINK_REGEX } from 'common/config';
import { IGroupedMessage, IMessage, MessageCollectionOrderField } from 'app/models/message.models';
import { MessageType } from 'entities/Messages/components/TypeComponents/MessageType';
import { AssessmentType } from 'entities/Messages/components/TypeComponents/AssessmentType';
import { VideoCallType } from 'entities/Messages/components/TypeComponents/VideoCallType';
import { VoiceCallType } from 'entities/Messages/components/TypeComponents/VoiceCallType';
import { LucyType } from 'entities/Messages/components/TypeComponents/LucyType';

export const groupMessagesByDate = (data: IMessage[] | null): IGroupedMessage[] => {
  const groupedData: { [key: string]: IMessage[] } = {};

  data?.forEach((item) => {
    const date = item.createdAt.split('T')[0];

    if (!groupedData[date]) {
      groupedData[date] = [];
    }

    groupedData[date].push(item);
  });

  return Object.keys(groupedData).map((date) => ({ date, items: groupedData[date] }));
};

export const renderMessageItem = (item: IMessage, currentUsername?: string) => {
  switch (item.type) {
    case EMessageType.Message: {
      return <MessageType key={item.id} item={item} isCurrentUserMessage={item.userName === currentUsername} />;
    }
    case EMessageType.StartConversation: {
      return (
        <div key={item.id} className="messages__block-start-chat">
          Chat session has started
        </div>
      );
    }
    case EMessageType.EndConversation: {
      return (
        <div key={item.id} className="messages__block-end-chat">
          Chat session has ended
        </div>
      );
    }
    case EMessageType.Assessment: {
      return <AssessmentType key={item.id} item={item} />;
    }
    case EMessageType.VideoCall: {
      return <VideoCallType key={item.id} item={item} />;
    }
    case EMessageType.Call: {
      return <VoiceCallType key={item.id} item={item} />;
    }
    case EMessageType.Lucy: {
      return <LucyType key={item.id} item={item} />;
    }
    default: {
      return null;
    }
  }
};

export const getMessagesPayload = (conversationId: string) => {
  return {
    conversationId,
    orderField: 'createdAt' as MessageCollectionOrderField,
    orderDirection: EOrderDirectionShort.ASC,
    limit: 0,
  };
};

export const messageToHtmlString = (message: string): string => {
  const str = message.replace(LINK_REGEX, (match) => `<a href="${match}" target="_blank">${match}</a>`);
  return `<p>${str}</p>`;
};
