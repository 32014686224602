import { createModel } from '@rematch/core';
import { axiosErrorHandler } from 'common/helpers/axios.helper';
import { IRootModel } from 'app/store/slices';
import { IPatient, IPatientState } from 'app/models/patient.models';
import { patientService } from 'app/store/services/patient.service';

export const patient = createModel<IRootModel>()({
  state: {
    data: null,
    loading: false,
  } as IPatientState,
  reducers: {
    setPatient: (state, payload: IPatient | null) => ({ ...state, data: payload }),
    setPatientLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
  },
  effects: (dispatch) => ({
    async getPatientByEventId(eventId: number) {
      dispatch.patient.setPatientLoading(true);

      await patientService
        .getPatientByEventId(eventId)
        .then((response) => {
          dispatch.patient.setPatient(response);
        })
        .catch(axiosErrorHandler)
        .finally(() => {
          dispatch.patient.setPatientLoading(false);
        });
    },
  }),
});
