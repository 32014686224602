import React from 'react';
import { useIdleTimer } from 'react-idle-timer';

interface IComponentProps {
  timeout: number;
  onIdle: () => void;
}

export const IdleTimer: React.FC<IComponentProps> = ({ timeout, onIdle }) => {
  useIdleTimer({ timeout, onIdle });

  return null;
};
