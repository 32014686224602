import React, { FC } from 'react';
import { Button, Spin } from 'antd';
import { formatPhoneNumber } from 'common/helpers/phone.helper';
import { ReactComponent as PhoneIcon } from 'app/assets/images/svg/phone.svg';

interface IComponentProps {
  phone?: string;
  loading: boolean;
  onCall: boolean;
  onCallClick: () => void;
}

export const VoiceCall: FC<IComponentProps> = ({ phone, loading, onCall, onCallClick }) => {
  return (
    <div className="call__voice">
      <div className="call__voice-title">Calls</div>

      <div className="call__voice-container">
        <div className="call__voice-phone">Phone: {formatPhoneNumber(phone)}</div>

        <Button
          className="btn blue"
          icon={loading ? <Spin size="small" /> : <PhoneIcon />}
          onClick={onCallClick}
          disabled={onCall}
        >
          Call
        </Button>
      </div>
    </div>
  );
};
