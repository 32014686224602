import { createModel } from '@rematch/core';
import { EModalKey } from 'common/const/enum';
import { IRootModel } from 'app/store/slices';
import { IModalCollection } from 'app/models/modal.models';

const commonState = { open: false };

export const modalCollection = createModel<IRootModel>()({
  state: {
    [EModalKey.TimeEditor]: commonState,
    [EModalKey.Inactivity]: commonState,
    [EModalKey.Complete]: commonState,
    [EModalKey.Escalate]: commonState,
  } as IModalCollection,
  reducers: {
    showModal: (state, payload: EModalKey) => ({ ...state, [payload]: { open: true } }),
    hideModal: (state, payload: EModalKey) => ({ ...state, [payload]: { open: false } }),
  },
  effects: (dispatch) => ({
    showInactivityModal() {
      dispatch.modalCollection.hideModal(EModalKey.TimeEditor);
      dispatch.modalCollection.hideModal(EModalKey.Complete);
      dispatch.modalCollection.hideModal(EModalKey.Escalate);
      dispatch.modalCollection.showModal(EModalKey.Inactivity);
    },
  }),
});
