import React, { FC } from 'react';
import { Radio } from 'antd';
import { IOption } from 'app/models/global.models';

interface IComponentProps {
  btns: IOption[];
  value: string;
  onChange: (value: string) => void;
}

const FilterRadioComponent: FC<IComponentProps> = ({ btns, value, onChange }) => {
  return (
    <Radio.Group className="filter-radio" value={value} onChange={(e) => onChange(e.target.value)} buttonStyle="solid">
      {btns.map((btn, index) => {
        return (
          <Radio.Button className="filter-radio__btn" key={index} value={btn.value}>
            {btn.label}
          </Radio.Button>
        );
      })}
    </Radio.Group>
  );
};

export const FilterRadio = FilterRadioComponent;
