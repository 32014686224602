import React, { FC } from 'react';
import dayjs from 'dayjs';
import { EDateFormat, EHistoryType, EHistoryTypeLabel } from 'common/const/enum';
import { IHistoryItem, IHistoryItemComment } from 'app/models/history.models';

interface IComponentProps {
  item: IHistoryItem;
}

export const HistoryItem: FC<IComponentProps> = ({ item }) => {
  return (
    <div className="history__item">
      <div className="history__item-header">
        <span>{dayjs(item.createdAt).format(EDateFormat.FullDateTime12Slash)}</span>

        <span>
          {item.actionType !== EHistoryType.received && (
            // @ts-ignore
            <span>by: {item.user?.name ? item.user.name : 'System'}</span>
          )}
        </span>
      </div>

      <div className="history__item-message">{EHistoryTypeLabel[item.actionType]}</div>

      {(item.data as IHistoryItemComment)?.comment && (
        <div className="history__item-comment">{`Comment: ${(item.data as IHistoryItemComment)?.comment}`}</div>
      )}
    </div>
  );
};
