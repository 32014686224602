import React, { FC, useEffect, useState } from 'react';
import { Menu } from 'antd';
import type { MenuProps } from 'antd';
import { EEventMenuKey } from 'common/const/enum';
import { useSse } from 'common/hooks/useSse';
import { useAppDispatch } from 'common/hooks/useAppDispatch';
import { useAppSelector } from 'common/hooks/useAppSelector';
import { IMessage, IMessageItemVideo } from 'app/models/message.models';
import { Messages } from 'entities/Messages/components/Messages';
import { PatientMetrics } from 'entities/PatientMetrics/components/PatientMetrics';
import { getEventMenuItems } from 'entities/Events/Events.helper';
import { getMessagesPayload } from 'entities/Messages/Messages.helper';

interface IComponentProps {
  eventId: number;
  patientId: number;
  conversationId: string;
  canMessage: boolean;
}

export const EventContent: FC<IComponentProps> = ({ eventId, conversationId, canMessage, patientId }) => {
  const { messageCollection } = useAppSelector((state) => ({ messageCollection: state.messageCollection }));
  const { messageCollection: messageCollectionDispatch } = useAppDispatch();

  const [menuKey, setMenuKey] = useState<EEventMenuKey>(EEventMenuKey.Messages);
  const [messages, setMessages] = useState<IMessage[]>([]);
  const [unreadMessages, setUnreadMessages] = useState<number>(0);

  const { data: messageCollectionData, loading: messageCollectionLoading } = messageCollection;
  const { getMessages } = messageCollectionDispatch;

  const showUnreadMessagesBadge = menuKey === EEventMenuKey.PatientDetails;
  const eventMenuItems = getEventMenuItems(unreadMessages, showUnreadMessagesBadge);
  const payload = getMessagesPayload(conversationId);

  const onMenuKeyClick: MenuProps['onClick'] = (e) => {
    setUnreadMessages(0);
    setMenuKey(e.key as EEventMenuKey);
  };

  const renderEventContent = () => {
    switch (menuKey) {
      case EEventMenuKey.Messages: {
        return (
          <Messages
            eventId={eventId}
            canMessage={canMessage}
            messages={messages}
            loading={!messageCollectionData && messageCollectionLoading}
          />
        );
      }
      case EEventMenuKey.PatientDetails: {
        return <PatientMetrics patientId={patientId} />;
      }
      default: {
        return null;
      }
    }
  };

  useEffect(() => {
    if (menuKey === EEventMenuKey.Messages) {
      getMessages(payload);
    }
  }, [menuKey]);

  useEffect(() => {
    if (messageCollectionData) {
      setMessages(messageCollectionData);
    }
  }, [messageCollectionData]);

  useSse(
    (json) => {
      const newMessage = JSON.parse(json);

      setUnreadMessages((prev) => prev + 1);
      setMessages((prev) => {
        const message = prev.find((item) => item.id === newMessage.id);

        if (message) {
          (message.data as IMessageItemVideo).duration = newMessage.data.duration;
          return [...prev];
        }

        return [...prev, newMessage];
      });
    },
    () => {
      getMessages(payload);
    },
  );

  return (
    <>
      <Menu className="event__menu" mode="horizontal" items={eventMenuItems} selectedKeys={[menuKey]} onClick={onMenuKeyClick} />

      {renderEventContent()}
    </>
  );
};
