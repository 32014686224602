import { createModel } from '@rematch/core';
import { axiosErrorHandler } from 'common/helpers/axios.helper';
import { IRootModel } from 'app/store/slices';
import { IPhysician, IPhysicianCollectionState } from 'app/models/physicians.models';
import { physicianService } from 'app/store/services/physicians.service';

export const physicianCollecton = createModel<IRootModel>()({
  state: {
    data: null,
    loading: false,
  } as IPhysicianCollectionState,
  reducers: {
    setPhysicianCollecton: (state, payload: IPhysician[]) => ({ ...state, data: payload }),
    setPhysicianCollectonLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
  },
  effects: (dispatch) => ({
    async getPhysicianCollecton() {
      dispatch.physicianCollecton.setPhysicianCollectonLoading(true);

      await physicianService
        .getPhysicianCollecton()
        .then((response) => {
          dispatch.physicianCollecton.setPhysicianCollecton(response);
        })
        .catch(axiosErrorHandler)
        .finally(() => {
          dispatch.physicianCollecton.setPhysicianCollectonLoading(false);
        });
    },
  }),
});
