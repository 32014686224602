export const envConfig = (window as any).env as {
  EVENT_IDLE_LIMIT_MS: string;
  EVENT_MOVE_TO_QUEUE_LIMIT_MS: string;
};

export const DEFAULT_DEBOUNCE_DELAY = 800;
export const TABLE_LIMIT = 10;
export const EVENT_IDLE_LIMIT_MS = isNaN(Number(envConfig.EVENT_IDLE_LIMIT_MS)) ? 120000 : Number(envConfig.EVENT_IDLE_LIMIT_MS);
export const EVENT_MOVE_TO_QUEUE_LIMIT_MS = isNaN(Number(envConfig.EVENT_MOVE_TO_QUEUE_LIMIT_MS))
  ? 60000
  : Number(envConfig.EVENT_MOVE_TO_QUEUE_LIMIT_MS);
export const LINK_REGEX = /(?:https?:\/\/|www\.)[^\s]+/g;
export const NEW_EVENT_NOTIFICATION_DURATION_S = 60;
